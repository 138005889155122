import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../App';

function PageLogout() {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        userContext.logout();
        window.location.href = userContext.getLogoutUrl();
    }, [userContext, navigate]);

    return null;
}

export default PageLogout;