import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

function TopBarUserNotifications({ notifications, markNotificationAsRead }) {
    const [showNotifications, setShowNotifications] = useState(false);
    const wrapperEl = useRef(null);

    const navigate = useNavigate();

    const handleOutsideClick = useCallback(e => {
        if (wrapperEl.current && !wrapperEl.current.contains(e.target)) {
            setShowNotifications(false);
        }
    }, []);

    function hasUnreadNotifications() {
        if (!notifications || notifications === undefined) return false;
        return notifications.filter(notification => !notification.is_read).length > 0;
    }

    function handleNotificationClick(notification) {
        setShowNotifications(false);

        if (!notification.is_read) markNotificationAsRead(notification.id);
        if (notification.object_info) navigateToObject(notification.object_info);
    }

    function navigateToObject(object) {
        if (object.post_type !== 'prikbord_post') return;
        if (object.topicSlugs.length === 0) return;

        navigate('/prikbord/topics/' + object.topicSlugs[0] + '#post-' + object.id);
    }

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideClick);
        return () => document.removeEventListener('mouseup', handleOutsideClick);
    }, [handleOutsideClick]);

    return (<>
        <div className="top-bar__notifications" ref={wrapperEl}>
            <button type="button" className={'top-bar__notifications-btn' + (hasUnreadNotifications() ? ' new-messages' : '')} onClick={() => setShowNotifications(prev => !prev)}>Notificaties</button>

            <ul className={'notifications-list' + (showNotifications ? ' show' : '')}>
                <li className="top">
                    <button type="button">Berichten</button>
                </li>

                {notifications.length === 0 && <li className="notifications-list__no-notifications">Je hebt momenteel geen berichten.</li>}

                {notifications.map((notification, index) => {
                    return (
                        <li key={'notification-' + index}>
                            <button type="button" onClick={() => handleNotificationClick(notification)}>
                                <div className="icon" style={{ backgroundImage: `url(${notification.source_user_avatar})` }}></div>
                                <div className="text" dangerouslySetInnerHTML={{ __html: notification.notification }}></div>
                                <div className={'read-indicator ' + (notification.is_read ? 'read' : 'unread')}></div>
                            </button>
                        </li>
                    );
                })}
            </ul>
        </div>
    </>);
}

export default TopBarUserNotifications;