import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { UserContext } from '../../App';

function VakcurriculumFormWrapper() {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userContext.isLoggedIn) return navigate(`/login?redirect=${encodeURIComponent(window.location.href)}`);
    }, [userContext, navigate]);

    return (
        <>
            { userContext.isLoggedIn &&
                <Outlet />
            }
        </>
    );
}

export default VakcurriculumFormWrapper;