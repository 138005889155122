import React from 'react';

function TrajectSelectModal({ show, setShow, options, title = 'Kies een traject:', callback }) {
    function selectOption(trajectSlug) {
        setShow(false);
        callback(trajectSlug);
    }

    return (
        <section className={'niveau-select-modal' + (show ? ' open' : '')}>
            <div className="niveau-select-modal__inner-wrapper">
                <button className="niveau-select-modal__close-btn" onClick={() => callback(null)} type="button">Sluiten</button>

                <div className="niveau-select-modal__inner">
                    <span className="niveau-select-modal__title">{title}</span>

                    <ul className="niveau-select-modal__options">
                        {options.map(option => <li key={Math.random().toString(16).slice(2)}><button type="button" className="btn btn--white" onClick={() => selectOption(option.slug)}>{option.title}</button></li> )}
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default TrajectSelectModal;