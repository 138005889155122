import React, { useRef, useState, useContext } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/nl';

import TopicComment from './TopicComment';
import NewTopicPostBox from './NewTopicPostBox';
import { UserContext } from '../../App';
import { Link } from 'react-router-dom';

const RelativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(RelativeTime);
dayjs.locale('nl');

function TopicPost({ post, settings, setTopicPost, topic, setTopic }) {
    const userContext = useContext(UserContext);

    const newCommentInputEl = useRef(null);
    const newCommentFormEl = useRef(null);

    const [editState, setEditState] = useState(false);
    const [isSubmittingComment, setIsSubmittingComment] = useState(false);
    const [isSendingPostFollowToggle, setIsSendingPostFollowToggle] = useState(false);
    const [newOwnComments, setNewOwnComments] = useState([]);
    const [postIsLoading, setPostIsLoading] = useState(false);

    const showCommentsLimit = 3;
    const [limitCommentDisplay, setLimitCommentDisplay] = useState(true);

    const userCanEditPost = post ? (userContext.getUserPermissionValue('roles').includes('administrator') || parseInt(post.author.id) === userContext.getCurrentUser().id) : false;

    function enableEditState() {
        setEditState(true);
    }

    function cancelEditState() {
        setEditState(false);
    }

    function submitNewComment(e) {
        e.preventDefault();

        if (userContext.fetchHeaders === null || isSubmittingComment) return;

        if (newCommentInputEl.current.value.trim() === '') {
            newCommentInputEl.current.focus();
            return;
        }

        setIsSubmittingComment(true);

        fetch(settings.apiBaseUrl + 'prikbord/add-topic-post-comment', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({
                postId: post.id,
                comment: newCommentInputEl.current.value.trim()
            })
        })
            .then(response => response.json())
            .then(result => {
                setIsSubmittingComment(false);

                if (result.success) {
                    const postClone = JSON.parse(JSON.stringify(post));
                    postClone.comments.push(result.comment);

                    if (post.comments.length > (showCommentsLimit - 1)) setNewOwnComments([...newOwnComments, result.comment]);
                    setTopicPost(postClone, false);

                    newCommentInputEl.current.value = '';
                    newCommentInputEl.current.style.height = '';
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else if (result.message === 'comments-closed') alert('Opmerkingen voor dit bericht zijn uitgeschakeld.');
                    else alert('Oeps, er ging iets fout bij het plaatsen van je opmerking. Probeer het nogmaals.');
                }
            })
        ;
    }

    function togglePostFollowState(postId) {
        setIsSendingPostFollowToggle(true);

        fetch(settings.apiBaseUrl + 'prikbord/toggle-topic-post-follow', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ postId: postId })
        })
            .then(response => response.json())
            .then(result => {
                setIsSendingPostFollowToggle(false);

                if (result.success) {
                    const postClone = JSON.parse(JSON.stringify(post));

                    postClone.followers = result.post_followers;
                    postClone.current_user_follows_post = result.current_user_follows_post;

                    setTopicPost(postClone, false);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                }
            })
        ;
    }

    function deletePost() {
        if (!window.confirm('Weet je zeker dat je dit bericht wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.')) return;
        if (!window.confirm('Zeker weten?')) return;

        setPostIsLoading(true);

        fetch(settings.apiBaseUrl + 'prikbord/delete-topic-post', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ postId: post.id })
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    setTopicPost(post, true);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout. Probeer het nogmaals.');
                }
            });
    }

    return (
        <article className={`topic-post ${(postIsLoading ? ' topic-post--loading' : '')}${post.is_pinned ? ' topic-post--pinned' : ''}`} id={`topic-post_${post.id}`}>
            <div className="topic-post__main">
                <div className="topic-post__meta topic-post__meta--no-margin">        
                    <div className="topic-post__author">
                        <div className="avatar" style={{ backgroundImage: `url(${post.author.avatar})` }}></div>
                        <div className="meta">
                            <span>{post.author.name}</span>
                            <span className="date">{dayjs(post.date).fromNow()} in <Link to={'/prikbord/topics/' + post.topic_slug}>{post.topic_name}</Link></span>
                        </div>
                    </div>

                    { ! editState &&
                        <div className="topic-post__meta-options">
                            { post.is_pinned && <span className="pin-indicator" type="button">Gepind</span> }

                            { userCanEditPost && <>
                                <button type="button">Opties</button>

                                <ul>
                                    <li><button className="edit" type="button" onClick={enableEditState}>Bericht bewerken</button></li>
                                    <li><button className="delete" type="button" onClick={deletePost}>Bericht verwijderen</button></li>
                                </ul>
                            </> }
                        </div>
                    }
                </div>

                { ! editState && <>
                    <span className="topic-post__title heading:3">{post.name}</span>

                    {post.media_type === 'image' && post.image.default &&
                        <img className="topic-post__image" src={post.image.default} alt={post.name} />
                    }

                    <div className="topic-post__content default-styles" dangerouslySetInnerHTML={{ __html: post.content }} ></div>

                    {post.embed_code &&
                        <div className="topic-post__embed" dangerouslySetInnerHTML={{ __html: post.embed_code }} ></div>
                    }
                </> }

                { editState === true && <NewTopicPostBox topic={topic} setTopic={setTopic} settings={settings} existingPost={post} cancelEditState={cancelEditState} /> }

                { ! editState && <>
                    <div className="topic-post__meta">
                        <ul className="topic-post__followers">
                            {
                                post.followers.map((follower, index) => {
                                    if (index < 4) return <li key={'follower-' + index} style={{ backgroundImage: `url(${follower.avatar})` }}></li>;
                                    else if (index === 4) return <li key={'follower-' + index} className="additional">+{post.followers.length - 4}</li>;
                                    else return null;
                                })
                            }
                        </ul>

                        <div className="topic-post__btn-wrapper">
                            <button type="button" className="btn btn--alt btn--mustard-outline" disabled={isSendingPostFollowToggle} onClick={() => togglePostFollowState(post.id)}>{post.current_user_follows_post ? 'Niet meer volgen' : 'Volgen'}</button>
                            {post.comment_status === 'open' && <button type="button" className="btn btn--alt btn--mustard" onClick={() => newCommentInputEl.current.focus()}>Reageer</button>}
                        </div>
                    </div>
                </> }
            </div>

            { ! editState &&
                <div className="topic-post__comments">
                    {post.comments.map((comment, index) => {
                        if (limitCommentDisplay && index > (showCommentsLimit - 1)) return null;
                        return (<TopicComment comment={comment} key={Math.random().toString(16).slice(2)} />);
                    })}

                    {post.comments.length > (showCommentsLimit - 1) && limitCommentDisplay && newOwnComments.map(comment => {
                        return (<TopicComment comment={comment} key={Math.random().toString(16).slice(2)} />);
                    })}

                    {post.comments.length > showCommentsLimit && <button type="button" onClick={() => setLimitCommentDisplay(! limitCommentDisplay)} className="topic-post__show-more-comments">{ limitCommentDisplay ? `Alle reacties weergeven (${post.comments.length})` : `Reacties verbergen` }</button>}

                    <div className={'topic-post__new-comment-box new-comment-box' + (isSubmittingComment ? ' new-comment-box--loading' : '')}>
                        {post.comment_status === 'closed' ?
                            <div className="new-comment-box__closed">{post.comments.length === 0 ? 'Opmerkingen' : 'Nieuwe opmerkingen'} voor dit bericht zijn uitgeschakeld.</div>
                            :
                            <form ref={newCommentFormEl} onSubmit={submitNewComment}>
                                <div className="new-comment-box__avatar" style={{ backgroundImage: `url(${userContext.getCurrentUser().avatar})` }}></div>
                                <textarea ref={newCommentInputEl} onChange={(e) => { e.target.style = 0; e.target.style.height = e.target.scrollHeight + 'px'; }} className="new-comment-box__input input" rows="1" placeholder="Opmerking plaatsen"></textarea>
                                <button type="submit" className="new-comment-box__submit-btn">Opmerking plaatsen</button>
                            </form>
                        }
                    </div>
                </div>
            }
        </article>
    );
}

export default TopicPost;