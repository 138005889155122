import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logoIcon from './../../assets/images/lpb-logo-icon.svg';
import cardsIcon from './../../assets/images/icon-cards.svg';
import plusIconPurple from './../../assets/images/icon-plus-purple.svg';

function VakcurriculumFormHeader({ showCardsLink = false }) {
    const navigate = useNavigate();

    function goToCards() {
        navigate('/vakcurriculum-formulier');
    }

    function goToNewCard() {
        navigate('/vakcurriculum-formulier/new');
    }

    return (
        <nav className="lpb-vakcurriculum-form-header">
            <div className="lpb-vakcurriculum-form-header__inner">
                <div className="lpb-vakcurriculum-form-header__start">
                    <Link className="lpb-vakcurriculum-form-header__logo" aria-label="Terug naar start" style={{ backgroundImage: `url(${logoIcon})` }} to="/"></Link>
                    <span className="lpb-vakcurriculum-form-header__title">Vakcurriculum<br />formulier</span>
                </div>

                {!showCardsLink &&
                    <div className="lpb-vakcurriculum-form-header__cards">
                        <button type="button" onClick={goToNewCard}>Nieuwe kaart<span style={{ backgroundImage: `url(${plusIconPurple})` }}></span></button>
                    </div>
                }

                {showCardsLink &&
                    <div className="lpb-vakcurriculum-form-header__cards">
                        <button type="button" onClick={goToCards}>Mijn kaarten<span style={{ backgroundImage: `url(${cardsIcon})` }}></span></button>
                    </div>
                }
            </div>
        </nav>
    );
}

export default VakcurriculumFormHeader;