import React, { useState, useEffect, useRef, useContext } from 'react';

import { UserContext } from '../App';

function FeedbackModule({ settings }) {
    const userContext = useContext(UserContext);

    const [showModule, setShowModule] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const descriptionInputEl = useRef(null);

    function submit(e) {
        e.preventDefault();

        const descriptionVal = descriptionInputEl.current.value.trim();

        if (descriptionVal === '') {
            alert('Vul eerst beide velden in om de feedback te kunnen verzenden.');
            return;
        }

        if (userContext.fetchHeaders === null) {
            return;
        }

        setIsSuccess(false);
        setIsLoading(true);

        try {
            fetch(settings.apiBaseUrl + 'send-feedback', {
                method: 'POST',
                headers: userContext.fetchHeaders,
                body: JSON.stringify({
                    location: window.location.href,
                    description: descriptionVal
                })
            })
                .then(result => result.json())
                .then(response => {
                    setIsLoading(false);

                    if (response.success) {
                        descriptionInputEl.current.value = '';
                        setIsSuccess(true);
                    }
                    else {
                        alert('Oeps.. Er ging iets niet helemaal goed. Probeer het nogmaals of neem contact met ons op.');
                    }
                });
        } catch (error) {
            console.error('Error sending feedback', error);
        }
    }

    function keyUp(e) {
        if (e.key.toLowerCase() === 'escape') {
            setShowModule(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, []);

    return (<>
        <button className="feedback-module-trigger" onClick={() => setShowModule(true)}>Geef feedback</button>

        <div className={`feedback-module${showModule ? ' show' : ''}${isLoading ? ' loading' : ''}`} onClick={() => setShowModule(false)}>
            <div className="feedback-module__inner">
                <button className="feedback-module__close-btn" onClick={() => setShowModule(false)}>Sluiten</button>

                <form className="feedback-module__form" onSubmit={submit}>
                    <label>Omschrijving</label>
                    <p>Geef een duidelijke omschrijving van de feedback of het probleem.</p>
                    <textarea className="input input--alt" placeholder="Omschrijving" rows="4" ref={descriptionInputEl}></textarea>

                    <button type="submit" className="btn btn--mustard">Feedback verzenden</button>
                </form>

                {isLoading && <div className="feedback-module__loading">Verzenden..</div>}
                {isSuccess && <div className="feedback-module__success">Gelukt!</div>}
            </div>
        </div>
    </>);
}

export default FeedbackModule;