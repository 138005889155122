import React, { useEffect, useState, useRef } from 'react';

function VakcurriculumFormAddTermDialog({ show, setShow, addTerm, loading }) {
    const inputEl = useRef(null);
    const [termName, setTermName] = useState('');

    useEffect(() => {
        if (show && inputEl.current) inputEl.current.focus();
    }, [show]);

    return (
        <div className={'lpb-dialog' + (show ? ' show' : '')}>
            <div className="lpb-dialog__inner">
                <span className="lpb-dialog__title">Item toevoegen</span>

                {!loading ?
                    <form className="lpb-dialog__form" onSubmit={(e) => { e.preventDefault(); addTerm(termName); }}>
                        <div className="input-wrapper">
                            <input ref={inputEl} type="text" onChange={(e) => setTermName(e.target.value)} className="lpb-dialog__input input" placeholder="Voer een titel in" />
                        </div>

                        <div className="lpb-dialog__btn-wrapper">
                            <button type="button" className="vakcurriculum-btn vakcurriculum-btn--full-white" onClick={() => setShow(false)}>Annuleren</button>
                            <button type="submit" className="vakcurriculum-btn vakcurriculum-btn--full-mustard">Toevoegen</button>
                        </div>
                    </form>
                : <div className="lpb-dialog__loader">Opslaan...</div>}
            </div>
        </div>
    );
}

export default VakcurriculumFormAddTermDialog;