import React, { useState, useRef, useEffect, useContext, createContext, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import gsap from 'gsap';

import MainLayoutWrapper from './MainLayoutWrapper';
import { getLoginToken } from './../helpers/UserHelper';
import { UserContext } from '../App';
import VakcurFilter from './VakcurFilter';
import VakcurHistoryModal from './VakcurHistoryModal';
import VakcurSearchModal from './VakcurSearchModal';

export const VakcurContext = createContext(null);

function VakcurLayoutWrapper({ settings }) {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [showSearchOverlay, setShowSearchOverlay] = useState(false);

    const [vakcurKlassen, setVakcurKlassen] = useState([]);
    const [vakcurVakken, setVakcurVakken] = useState([]);
    const [vakcurTrajecten, setVakcurTrajecten] = useState([]);
    const [vakcurLestypen, setVakcurLestypen] = useState([]);

    const [vakcurTijdvakken, setVakcurTijdvakken] = useState([]);
    const [vakcurVakoverstijgingen, setVakcurVakoverstijgingen] = useState([]);
    const [vakcurHuiswerk, setVakcurHuiswerk] = useState([]);
    const [vakcurLeerfocus, setVakcurLeerfocus] = useState([]);
    const [vakcurAfsluitingen, setVakcurAfsluitingen] = useState([]);
    const [vakcurPta, setVakcurPta] = useState([]);
    const [vakcurPortfolio, setVakcurPortfolio] = useState([]);

    const [vakcurStart, setVakcurStart] = useState([]);
    const [historyCards, setHistoryCards] = useState([]);

    const pageTransitionElRef = useRef(null);

    const [activeFilters, setActiveFilters] = useState({
        klas: null,
        vak: null,
        traject: null,
        lestype: null,

        tijdvak: null,
        vakoverstijging: null,
        huiswerk: null,
        leerfocus: null,
        afsluiting: null,
        pta: null,
        portfolio: null
    });

    const [activeSearchFilters, setActiveSearchFilters] = useState({
        klas: null,
        vak: null,
        traject: null,
        lestype: null,

        tijdvak: null,
        vakoverstijging: null,
        huiswerk: null,
        leerfocus: null,
        afsluiting: null,
        pta: null,
        portfolio: null
    });

    const nav = useCallback((navTo) => {
        gsap.timeline()
            .to(pageTransitionElRef.current, { duration: 0.2, opacity: 1, ease: 'power2.inOut' })
            .add(() => pageTransitionElRef.current.classList.add('in'), '-=0.2')
        ;

        setTimeout(() => {
            navigate(navTo);

            if (pageTransitionElRef.current) {
                pageTransitionElRef.current.classList.remove('in');
                gsap.to(pageTransitionElRef.current, { delay: 1, duration: 0.5, opacity: 0, ease: 'power2.inOut' });
            }
        }, 1100);
    }, [navigate]);

    const getActiveFilterCount = useCallback(() => {
        let count = 0;
        for (const key in activeFilters) if (activeFilters[key] !== null) count++;
        return count;
    }, [activeFilters]);

    const getActiveSearchFilterCount = useCallback(() => {
        let count = 0;
        for (const key in activeSearchFilters) if (activeSearchFilters[key] !== null) count++;
        return count;
    }, [activeSearchFilters]);

    const setKlasFilter = useCallback((slug, isSearch = false, useTransition = true) => {
        const klas = slug ? vakcurKlassen.find(klas => klas.slug === slug) : null;
        const navFunction = useTransition ? nav : navigate;

        if (!isSearch) setActiveFilters({ ...activeFilters, klas: (klas ? klas.slug : null) });
        else setActiveSearchFilters({ ...activeSearchFilters, klas: (klas ? klas.slug : null) });

        if (!isSearch) {
            // Wanneer we al op de klas pagina zijn, navigeer naar de juiste klas
            if (!klas && location.pathname.startsWith('/vakcurriculum/klas/')) navFunction('/vakcurriculum');
            else if (!!klas && location.pathname.startsWith('/vakcurriculum/klas/')) navFunction(`/vakcurriculum/klas/${klas.slug}`);
        }
    }, [activeFilters, activeSearchFilters, vakcurKlassen, location.pathname, nav, navigate]);

    const setVakFilter = useCallback((slug, isSearch = false) => {
        const vak = slug ? vakcurVakken.find(v => v.slug === slug) : null;

        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, vak: (vak ? vak.slug : null) } });
        else setActiveSearchFilters(nextState => { return { ...nextState, vak: (vak ? vak.slug : null) } });

        if (!isSearch) {
            // Wanneer we geen vak hebben maar wel op de vak pagina zijn
            if (!vak && location.pathname.startsWith('/vakcurriculum/vak/')) {
                // Wanneer we wel een klas hebben, navigeer naar de klas
                if (!!activeFilters.klas) nav(`/vakcurriculum/klas/${activeFilters.klas}`);
                // Zo niet, navigeer naar home
                else nav('/vakcurriculum');
            }

            // Wanneer we een vak hebben en op de vak pagina zijn, navigeer naar het juiste vak
            else if (!!vak && location.pathname.startsWith('/vakcurriculum/vak/')) nav(`/vakcurriculum/vak/${vak.slug}`);

            // Wanneer we een vak hebben en op de klas pagina zijn, navigeer naar de vak pagina
            else if (!!vak && location.pathname.startsWith('/vakcurriculum/klas/')) nav(`/vakcurriculum/vak/${vak.slug}`);
        }
    }, [activeFilters.klas, location.pathname, nav, vakcurVakken]);

    const setTrajectFilter = useCallback((slug, isSearch = false) => {
        const traject = slug ? vakcurTrajecten.find(t => t.slug === slug) : null;
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, traject: (traject ? traject.slug : null) } });
        else setActiveSearchFilters(nextState => { return { ...nextState, traject: (traject ? traject.slug : null) } });
    }, [vakcurTrajecten]);

    const setLestypeFilter = useCallback((slug, isSearch = false) => {
        let lestype = slug ? vakcurLestypen.find(lt => lt.slug === slug) : null;
        if (!isSearch && !lestype && vakcurLestypen.length > 0) lestype = vakcurLestypen[0];

        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, lestype: (lestype ? lestype.slug : null) } });
        else setActiveSearchFilters(nextState => { return { ...nextState, lestype: (lestype ? lestype.slug : null) } });
    }, [vakcurLestypen]);

    const setTijdvakFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, tijdvak: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, tijdvak: slug } });
    }, []);

    const setVakoverstijgingFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, vakoverstijging: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, vakoverstijging: slug } });
    }, []);

    const setHuiswerkFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, huiswerk: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, huiswerk: slug } });
    }, []);

    const setLeerfocusFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, leerfocus: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, leerfocus: slug } });
    }, []);

    const setAfsluitingFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, afsluiting: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, afsluiting: slug } });
    }, []);

    const setPtaFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, pta: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, pta: slug } });
    }, []);

    const setPortfolioFilter = useCallback((slug, isSearch = false) => {
        if (!isSearch) setActiveFilters(nextState => { return { ...nextState, portfolio: slug } });
        else setActiveSearchFilters(nextState => { return { ...nextState, portfolio: slug } });
    }, []);

    const helperFunctions = {
        nav,

        setActiveFilters,
        setKlasFilter,
        setVakFilter,
        setTrajectFilter,
        setShowSearchOverlay,

        setLestypeFilter,
        setTijdvakFilter,
        setVakoverstijgingFilter,
        setHuiswerkFilter,
        setLeerfocusFilter,
        setAfsluitingFilter,
        setPtaFilter,
        setPortfolioFilter
    };

    function addHistoryCard(card) {
        const currentUser = userContext.getCurrentUser();
        if (! currentUser) return;

        const cardsFiltered = historyCards.filter(c => c.id !== card.id);
        const newCards = [card, ...cardsFiltered];

        setHistoryCards(newCards);
        localStorage.setItem(`u${currentUser.id}_vakcurriculum_history`, JSON.stringify(newCards));
    }

    // On first init, get the cards history from the local storage
    useEffect(() => {
        const currentUser = userContext.getCurrentUser();
        if (! currentUser) return;

        const history = localStorage.getItem(`u${currentUser.id}_vakcurriculum_history`);
        if (history) setHistoryCards(JSON.parse(history));
    }, [userContext]);

    useEffect(() => {
        if (getLoginToken() === null || userContext.fetchHeaders === null || vakcurKlassen.length > 0) return;

        try {
            fetch(settings.apiBaseUrl + 'vakcurriculum/general', {
                method: 'POST',
                headers: userContext.fetchHeaders,
            })
                .then(result => result.json())
                .then(response => {
                    if (!response.success) return;

                    setVakcurKlassen(response.vakcurriculum.klassen);
                    setVakcurVakken(response.vakcurriculum.vakken);
                    setVakcurTrajecten(response.vakcurriculum.trajecten);
                    setVakcurLestypen(response.vakcurriculum.lestypen);

                    setVakcurTijdvakken(response.vakcurriculum.tijdvakken);
                    setVakcurVakoverstijgingen(response.vakcurriculum.vakoverstijgingen);
                    setVakcurHuiswerk(response.vakcurriculum.huiswerk);
                    setVakcurLeerfocus(response.vakcurriculum.leerfocus);
                    setVakcurAfsluitingen(response.vakcurriculum.afsluitingen);
                    setVakcurPta(response.vakcurriculum.pta);
                    setVakcurPortfolio(response.vakcurriculum.portfolio);

                    setVakcurStart(response.vakcurriculum.page_start);

                    if (location.pathname.startsWith('/vakcurriculum/klas/')) setActiveFilters(nextState => { return { ...nextState, klas: location.pathname.split('/')[3] } });
                    else if (location.pathname.startsWith('/vakcurriculum/vak/')) setActiveFilters(nextState => { return { ...nextState, vak: location.pathname.split('/')[3] } });
                });
        } catch (error) {
            console.error(error);
        }
    }, [userContext.fetchHeaders, settings.apiBaseUrl, location.pathname, setActiveFilters, activeFilters, vakcurKlassen.length, setLestypeFilter]);

    useEffect(() => {
        if (vakcurLestypen.length === 0 || activeFilters.lestype !== null) return;
        setLestypeFilter(vakcurLestypen[0].slug);
    }, [vakcurLestypen, setLestypeFilter, activeFilters.lestype]);

    return (
        <VakcurContext.Provider value={{
            activeFilters,
            activeSearchFilters,
            helperFunctions,

            addHistoryCard,
            getActiveFilterCount,
            getActiveSearchFilterCount,

            klassen: vakcurKlassen,
            vakken: vakcurVakken,
            trajecten: vakcurTrajecten,
            lestypen: vakcurLestypen,

            tijdvakken: vakcurTijdvakken,
            vakoverstijgingen: vakcurVakoverstijgingen,
            huiswerk: vakcurHuiswerk,
            leerfocus: vakcurLeerfocus,
            afsluitingen: vakcurAfsluitingen,
            pta: vakcurPta,
            portfolio: vakcurPortfolio,

            startPage: vakcurStart
        }}>
            <MainLayoutWrapper settings={settings}>
                <Outlet key={location.pathname} />
            </MainLayoutWrapper>

            {location.pathname !== '/vakcurriculum' && <VakcurFilter />}

            <VakcurHistoryModal cards={historyCards} />
            <VakcurSearchModal settings={settings} show={showSearchOverlay} setShow={setShowSearchOverlay} />
            {/* <PageTransition show={showPageTransition} setShow={setShowPageTransition} /> */}


            <div className="page-transition" ref={pageTransitionElRef}>
                <div className="page-transition__item page-transition__item--l1"></div>
                <div className="page-transition__item page-transition__item--l2"></div>
                <div className="page-transition__item page-transition__item--r1"></div>
                <div className="page-transition__item page-transition__item--r2"></div>
            </div>
        </VakcurContext.Provider>
    );
}

export default VakcurLayoutWrapper;