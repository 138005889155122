import React, { useState, useEffect, useCallback } from 'react';
import VakcurListCard from './VakcurListCard';

function VakcurHistoryModal({ cards }) {
    const [show, setShow] = useState(false);

    const keyUp = useCallback(e => {
        if (e.key.toLowerCase() === 'escape') hide();
    }, []);

    function toggle() {
        if (show) hide();
        else setShow(true);
    }

    function hide() {
        setShow(false);
    }

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, [keyUp]);

    return (<>
        {!!cards.length && <>
            <button className={'vakcur-history-modal-trigger' + (show ? ' open' : '')} onClick={toggle}>Geschiedenis</button>

            <div className={'vakcur-history-modal' + (show ? ' show' : '')}>
                <div className="vakcur-history-modal__bg" onClick={hide}></div>

                <div className="vakcur-history-modal__inner">
                    <div className="vakcur-history-modal__top">
                        <span>Laatst bekeken</span>
                    </div>

                    <div className="vakcur-history-modal__content-wrapper">
                        {cards.map(card => <VakcurListCard key={card.id} card={card} callback={hide} />)}
                    </div>
                </div>
            </div>
        </>}
    </>);
}

export default VakcurHistoryModal;