import { useEffect, useState, useContext, useCallback, useRef, useLayoutEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import gsap from 'gsap';

import bgVideo from './../assets/videos/prikbord_bg.mp4';
import { PrikbordContext } from './PrikbordWrapper';
import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';
import { UserContext } from '../App';

function PagePrikbordTopics() {
    const userContext = useContext(UserContext);
    const navigate = useNavigate();

    const [activeHoverTitle, setActiveHoverTitle] = useState('Laden..');
    const [showTopicsList, setShowTopicsList] = useState(false);
    const { topics } = useContext(PrikbordContext);

    const topicMenuEl = useRef(null);
    const videoEl = useRef(null);
    const cloudsEl = useRef(null);
    const bgVideoPauseTime = 3;

    const handleOutsideTopicMenuClick = useCallback(e => {
        if (topicMenuEl.current && !topicMenuEl.current.contains(e.target)) {
            setShowTopicsList(false);
        }
    }, []);

    function keyUpEvent(e) {
        if (e.key.toLowerCase() === 'escape') {
            setShowTopicsList(false);
        }
    }

    function resize() {
        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
    }

    resize();

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > bgVideoPauseTime) {
                videoEl.current.pause();
                videoEl.current.currentTime = bgVideoPauseTime;
            }
        }, 100);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        };
    }, []);

    useEffect(() => {
        window.addEventListener('keyup', keyUpEvent);

        return () => window.removeEventListener('keyup', keyUpEvent);
    }, [userContext.isLoggedIn, navigate]);

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideTopicMenuClick);
        return () => document.removeEventListener('mouseup', handleOutsideTopicMenuClick);
    }, [handleOutsideTopicMenuClick]);

    useEffect(() => {
        if (topics.length === 0) return;
        gsap.set(cloudsEl.current, { opacity: 0 });

        videoEl.current.play();
        setActiveHoverTitle('');

        const context = gsap.context(() => {
            gsap.timeline({ delay: 0.5 })
                .set('.jsCloud', { yPercent: -100 })
                .set(cloudsEl.current, { opacity: 1 })
                .to('.jsCloud', { duration: 1.3,  yPercent: 0, stagger: 0.05, ease: 'bounce.out' })
                .set('.jsCloud', { clearProps: 'all' });
        }, cloudsEl);

        return () => context.revert();
    }, [topics]);

    return (<>
        <div className="prikbord-topics">
            { !! topics.length &&
                <div className={'prikbord-topics__topic-menu topic-menu' + (showTopicsList ? ' open' : '' )} ref={topicMenuEl}>
                    <div className="top-spacer"></div>

                    <div className="topic-menu__inner">
                        <button className="topic-menu__btn" type="button" onClick={() => { setShowTopicsList(!showTopicsList) }}>Topics</button>

                        <ul className="topic-menu__list">
                            <li className="title">Alle onderwerpen</li>

                            { topics.map((topic, index) => {
                                return <li className={'heading:3' + (index === 0 ? ' first' : '')} key={`topic-${index}`}><Link to={'/prikbord/topics/' + topic.slug}>{topic.name}</Link></li>;
                            }) }
                        </ul>
                    </div>
                </div>
            }

            <div className={'prikbord-topics__clouds' + (topics.length ? ' visible' : '')} ref={cloudsEl}>
                { topics.map((topic, index) => {
                    return (
                        <div key={`topic-${index}`} className={'prikbord-topics__cloud jsCloud topic-' + (index + 1) + (activeHoverTitle === topic.name ? ' active' : '')}><Link to={'/prikbord/topics/' + topic.slug} onMouseOver={() => setActiveHoverTitle(topic.name)}>{topic.name}</Link></div>
                    );
                }) }

                <span className="prikbord-topics__hover-title">{activeHoverTitle}</span>
            </div>

            <div className="prikbord-topics__bg-wrapper">
                <video ref={videoEl} src={bgVideo} type="video/mp4" playsInline muted></video>
            </div>
        </div>
    </>);
}

export default PagePrikbordTopics;