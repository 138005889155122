export default function setFullscreenVideoAspectRatio(el, videoRatio) {
    let newWidth = window.innerWidth;
    let newHeight = newWidth / videoRatio;

    if (newHeight < window.innerHeight) {
        newHeight = window.innerHeight;
        newWidth = newHeight * videoRatio;
    }

    el.style.width = newWidth + 'px';
    el.style.height = newHeight + 'px';
}