import React, { useState, useRef } from 'react';

import iconInfo from './../../assets/images/icon-info-light.svg';
import iconAddImage from './../../assets/images/icon-upload-img.svg';
import iconDeleteBtn from './../../assets/images/icon-delete-btn.svg';
import iconEditBtn from './../../assets/images/icon-edit-btn.svg';
import iconDeleteTag from './../../assets/images/icon-delete-tag.svg';

function VakcurriculumFormField({ field, updateField, showAddTermDialog, uploadFile, deleteFile, cardId, isChild, allFields }) {
    const fileUploadFormEl = useRef(null);
    const fileUploadInputEl = useRef(null);
    const repeatedTextRowInputEl = useRef(null);
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [isDeletingFile, setIsDeletingFile] = useState(false);
    const [showReadMore, setShowReadMore] = useState(false);
    let positionInfoFieldBottom = false;
    const fieldInfoText = getFieldInfoText();

    const [repeatedTextInputSuggestions, setRepeatedTextInputSuggestions] = useState([]);

    function getFieldInfoText() {
        if (field.slug === 'vakcurr_onderbouwing') {
            let desc = '';

            allFields.forEach(f => {
                if (f.slug === 'lpb_klas' && f['current-value'].length > 0) {
                    f['current-value'].forEach(termId => {
                        f['terms'].forEach(term => {
                            if (term.term_id === termId && field['class-description'][parseInt(term.name)]) {
                                desc += field['class-description'][term.name];
                                positionInfoFieldBottom = true;
                            }
                        });
                    });
                }
            });

            if (desc) return desc;
        }

        if (field['info-text']) return '<p>' + field['info-text'] + '</p>';
        return null;
    }

    function updateTextField(value) {
        field['current-value'] = value;
        updateField(field);
    }

    function onRepeaterTextRowInputElChange(e) {
        if (e.target.value && !! field.suggestions && Object.values(field.suggestions).length) {
            const suggestions = Object.values(field.suggestions).filter(suggestion => {
                let showSuggestion = suggestion.toLowerCase().includes(e.target.value.toLowerCase());

                field['current-value'].map(item => {
                    if (item.value === suggestion) showSuggestion = false;
                    return item;
                });

                return showSuggestion;
            });

            setRepeatedTextInputSuggestions(suggestions);
        }
        else {
            setRepeatedTextInputSuggestions([]);
        }
    }

    function toggleTerm(termId) {
        if (field['current-value'].includes(termId)) {
            if (field['min-options'] === 1 && field['current-value'].length === 1) {
                alert(`Je moet minimaal ${field['min-options']} ${field['min-options'] === 1 ? 'optie' : 'opties'} selecteren.`);
                return;
            }
            else {
                field['current-value'] = field['current-value'].filter((value) => value !== termId);
            }
        }
        else if (field['max-options'] !== -1 && field['max-options'] === 1) {
            field['current-value'] = [termId];
        }
        else if (field['max-options'] !== -1 && field['current-value'].length >= field['max-options']) {
            alert(`Je kunt maximaal ${field['max-options']} ${field['max-options'] === 1 ? 'optie' : 'opties'} selecteren.`);
            return;
        }
        else {
            field['current-value'].push(termId);
        }

        updateField(field);
    }

    function showFileDialog() {
        fileUploadInputEl.current.click();
    }

    function prepareFileUpload() {
        const formData = new FormData(fileUploadFormEl.current);
        if (!formData.get('file')) return;

        setIsUploadingFile(true);

        formData.append('post_id', cardId);
        formData.append('field_slug', field.slug);

        uploadFile(formData, () => setIsUploadingFile(false));
    }

    function prepareFileDelete() {
        // Show a confirm box to make sure the click was not by accident
        if (!window.confirm('Weet je zeker dat je deze afbeelding wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.')) return;

        // Continue!
        const formData = new FormData();

        setIsDeletingFile(true);

        formData.append('post_id', cardId);
        formData.append('field_slug', field.slug);

        deleteFile(formData, () => setIsDeletingFile(false));
    }

    function addRepeatedTextRowFormAction(e) {
        e.preventDefault();

        if (e.target[0].value.length === 0) return;
        addRepeatedTextRow(e.target[0].value);
    }

    function addRepeatedTextRow(value) {
        const fieldClone = JSON.parse(JSON.stringify(field));

        let isUnique = true;
        fieldClone['current-value'].map(item => {
            if (item.value === value) isUnique = false;
            return item;
        });

        if (!isUnique) {
            value = '';
            return;
        }

        fieldClone['current-value'].push({ value });
        updateField(fieldClone);

        repeatedTextRowInputEl.current.value = '';
        setRepeatedTextInputSuggestions([]);
    }

    function removeRepeatedTextRow(item) {
        const fieldClone = JSON.parse(JSON.stringify(field));

        fieldClone['current-value'] = fieldClone['current-value'].filter(i => i.value !== item.value);
        updateField(fieldClone);
    }

    function renderField(field) {
        switch (field.type) {
            case 'taxonomy': return (
                <ul className="lpb-form-field__term-list">
                    {field.terms.map(term => <li className={'vakcurriculum-btn vakcurriculum-btn--purple' + (field['current-value'].includes(term.term_id) ? ' vakcurriculum-btn--solid' : '')} onClick={() => toggleTerm(term.term_id)} key={'term-' + term.term_id}>{term.name}</li>)}
                    {field['allow-adding-terms'] && <ul><li className="vakcurriculum-btn vakcurriculum-btn--grey" onClick={() => showAddTermDialog(field.taxonomy)}>+</li></ul>}
                </ul>
            )

            case 'input-text': return (
                <div className="lpb-form-field__input-text">
                    <input maxLength={field['max-length'] === -1 ? 9999 * 9999 : field['max-length']} onChange={e => updateTextField(e.target.value)} value={field['current-value'] || ''} type="text" className="input" placeholder="Typ hier" />
                </div>
            )

            case 'input-textarea': return (
                <div className="lpb-form-field__input-textarea">
                    <textarea maxLength={field['max-length'] === -1 ? 9999 * 9999 : field['max-length']} className="input" defaultValue={field['current-value'] || ''} onChange={e => updateTextField(e.target.value)} placeholder="Typ hier"></textarea>
                </div>
            )

            case 'input-text-repeat': return (
                <div className="lpb-form-field__input-text-repeat">
                    <div className="wrapper">
                        {field['current-value'].map((item, index) => {
                            return <div className="form-field-tag" key={field.slug + index}>
                                <span>{item.value}</span>
                                <button type="button" onClick={() => removeRepeatedTextRow(item)} style={{ backgroundImage: `url(${iconDeleteTag})` }}>Verwijder</button>
                            </div>
                        })}

                        <form onSubmit={addRepeatedTextRowFormAction}>
                            <input ref={repeatedTextRowInputEl} onChange={onRepeaterTextRowInputElChange} type="text" className="input" placeholder="Typ hier en druk na ieder begrip op enter" />

                            {(!!repeatedTextInputSuggestions.length) &&
                                <ul className="suggestions">
                                    {repeatedTextInputSuggestions.map((suggestion, index) => {
                                        return <li key={field.slug + '-' + index}>
                                            <button type="button" onClick={() => {
                                                addRepeatedTextRow(suggestion);
                                            }}>{suggestion}</button>
                                        </li>
                                    })}
                                </ul>
                            }
                        </form>
                    </div>
                </div>
            )

            default: return null;
        }
    }

    return (
        <div className={'lpb-form-field' + (isChild ? ' is-child' : ' is-parent')}>
            <span className={'lpb-form-field__title' + (fieldInfoText ? ' has-info-text' : '')}>
                <span className="text">{field.title}
                    {fieldInfoText && <span style={{ backgroundImage: `url(${iconInfo})` }}><div className={'lpb-form-field__info-text default-styles' + (positionInfoFieldBottom ? ' lpb-form-field__info-text--bottom-center lpb-form-field__info-text--large' : '')} dangerouslySetInnerHTML={{ __html: fieldInfoText }}></div></span>}
                </span>
            </span>

            {field.description && <div className="lpb-form-field__desc default-styles" dangerouslySetInnerHTML={{ __html: field.description }}></div>}

            {field.description_read_more && <>
                <div className="lpb-form-field__read-more-wrapper">
                    {!showReadMore && <button type="button" className="lpb-form-field__read-more-btn vakcurriculum-btn vakcurriculum-btn--simple vakcurriculum-btn--small" onClick={() => setShowReadMore(true)}>Lees meer</button>}

                    {showReadMore && <>
                        <div className="lpb-form-field__desc default-styles" dangerouslySetInnerHTML={{ __html: field.description_read_more }}></div>
                        <button type="button" className="lpb-form-field__read-more-btn vakcurriculum-btn vakcurriculum-btn--simple vakcurriculum-btn--small" onClick={() => setShowReadMore(false)}>Verberg</button>
                    </>}
                </div>
            </>}

            {renderField(field)}

            {Object.keys(field['allows-file-upload']).length > 0 && (
                <div className="lpb-form-field__file-upload lpb-file-upload-field">
                    {isUploadingFile && <span className="lpb-file-upload-field__loading">Uw foto wordt verstuurd, een ogenblik geduld a.u.b.</span>}
                    {isDeletingFile && <span className="lpb-file-upload-field__loading">Een ogenblik geduld a.u.b.</span>}

                    {!isUploadingFile && !isDeletingFile && <>
                        <form encType="multipart/form-data" ref={fileUploadFormEl}>
                            <input name="file" type="file" ref={fileUploadInputEl} onChange={prepareFileUpload} />

                            {field['allows-file-upload'].existing_file_name !== null && <>
                                <button type="button" className="lpb-file-upload-field__icon" onClick={showFileDialog} style={{ backgroundImage: `url(${iconAddImage})` }}></button>
                                <div className="lpb-file-upload-field__file-title" onClick={showFileDialog}>{field['allows-file-upload'].existing_file_name}</div>
                            </>}

                            {!field['allows-file-upload'].existing_file_name && <>
                                <button type="button" className="lpb-file-upload-field__icon" onClick={showFileDialog} style={{ backgroundImage: `url(${iconAddImage})` }}></button>
                                <button type="button" className="lpb-file-upload-field__title" onClick={showFileDialog}>Voeg één afbeelding toe</button>
                            </>}

                            <div className="lpb-file-upload-field__btn-wrapper">
                                {field['allows-file-upload'].existing_file_name !== null && <>
                                    <button type="button" className="lpb-file-upload-field__delete-btn" onClick={prepareFileDelete} style={{ backgroundImage: `url(${iconDeleteBtn})` }}>Verwijderen</button>
                                    <button type="button" className="lpb-file-upload-field__edit-btn" onClick={showFileDialog} style={{ backgroundImage: `url(${iconEditBtn})` }}></button>
                                </>}
                            </div>
                        </form>

                        <span className="lpb-file-upload-field__max-upload-size">Maximale bestandsgrootte: {field['allows-file-upload']['max_upload_size_format']}</span>
                    </>}
                </div>
            )}

            {field.children && field.children.length > 0 &&
                <div className="lpb-form-field__children">
                    {field.children.map(childField => <VakcurriculumFormField key={childField.slug} field={childField} updateField={updateField} showAddTermDialog={showAddTermDialog} uploadFile={uploadFile} deleteFile={deleteFile} cardId={cardId} isChild={true} allFields={allFields} />)}
                </div>
            }
        </div>
    );
}

export default VakcurriculumFormField;