import React, { useEffect, useCallback } from 'react';

function InfoModal({ title = '', desc = '', show = true, setShow, onClose = null }) {
    const close = useCallback(() => {
        setShow(false);
        if (onClose !== null) onClose();
    }, [onClose, setShow]);

    const keyUp = useCallback((e) => {
        if (e.key.toLowerCase() === 'escape') {
            close();
        }
    }, [close]);

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
    }, [keyUp]);

    return (
        <section className={'info-modal' + (show ? ' open' : '')}>
            <div className="info-modal__bg" onClick={close}></div>

            <div className="info-modal__inner-wrapper">
                <div className="info-modal__inner">
                    <span className="info-modal__title heading:3" dangerouslySetInnerHTML={{ __html: title }}></span>
                    <div className="info-modal__desc default-styles" dangerouslySetInnerHTML={{ __html: desc }}></div>

                    <div className="info-modal__btn-wrapper">
                        <button className="info-modal__close-btn" onClick={close}>
                            <span>Ga verder</span>
                            <div className="arrow-btn"></div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default InfoModal;