import React, { useContext, useRef, useLayoutEffect } from 'react';

import { VakcurContext } from '../components/VakcurLayoutWrapper';
import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';
import CustomSelect from '../components/CustomSelect';

import bgVideo from './../assets/videos/kruin_bg.mp4';

function PageVakcurriculum() {
    const vakcurContext = useContext(VakcurContext);
    const videoEl = useRef(null);

    function resize() {
        if (videoEl.current === null) return;
        setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
    }
    resize();

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        return () => window.removeEventListener('resize', resize);
    }, []);

    function goToSelectedKlas() {
        const slug = vakcurContext.activeFilters.klas === null
            ? vakcurContext.klassen[0].slug
            : vakcurContext.activeFilters.klas;

        if (vakcurContext.activeFilters.klas === null) { // Only happens the first time. We need this to prevent showing the default placeholder in the selectbox
            vakcurContext.helperFunctions.setKlasFilter(slug);
        }

        // Reset vak and traject filters
        vakcurContext.helperFunctions.setVakFilter(null);
        vakcurContext.helperFunctions.setTrajectFilter(null);

        vakcurContext.helperFunctions.nav(`/vakcurriculum/klas/${slug}`);
    }

    return (<>
        {!!vakcurContext.klassen.length && <>
            <div className="page-start">
                <div className="page-start__main-wrapper">
                    <h1 className="page-start__title">{vakcurContext.startPage.title}</h1>
                    <div className="page-start__desc default-styles"><p>{vakcurContext.startPage.description}</p></div>

                    {!!vakcurContext.klassen.length &&
                        <div className="page-start__grade-select">
                            <span>Ontdek de</span>
                            <CustomSelect placeholderText="Kies een klas" options={vakcurContext.klassen.map(klas => { return { title: klas.human_name, slug: klas.slug } })} selectedOptionSlug={vakcurContext.activeFilters.klas !== null ? vakcurContext.activeFilters.klas : vakcurContext.klassen[0].slug} setSelectedOptionSlug={vakcurContext.helperFunctions.setKlasFilter} btnClasses="btn--mustard" compact={true} />
                            <button className="custom-select__go-btn arrow-btn" onClick={() => goToSelectedKlas()}>Ga!</button>
                        </div>
                    }
                </div>

                <div className="page-start__bg-wrapper">
                    <video ref={videoEl} src={bgVideo} type="video/mp4" autoPlay playsInline loop muted></video>
                </div>
            </div>
        </>}

        {vakcurContext.klassen.length === 0 && <div className="loading-msg">Laden..</div>}
    </>);
}

export default PageVakcurriculum;