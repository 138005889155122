import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../App';

function MyAccountGeneral({ settings, updateLocalUser }) {
    const userContext = useContext(UserContext);

    const [firstName, setFirstName] = useState(userContext.getCurrentUser().firstName || '');
    const [lastName, setLastName] = useState(userContext.getCurrentUser().lastName || '');

    const [notifierStatus, setNotifierStatus] = useState(''); // in-progress, success, error

    const fileUploadInputEl = useRef(null);
    const fileUploadFormEl = useRef(null);

    useEffect(() => {
        const currentUser = userContext.getCurrentUser();
        if (currentUser.firstName === firstName && currentUser.lastName === lastName) return;

        const timeout = setTimeout(() => {
            setNotifierStatus('in-progress');

            try {
                fetch(settings.apiBaseUrl + 'my-account/update-info', {
                    method: 'POST',
                    headers: userContext.fetchHeaders,
                    body: JSON.stringify({
                        user_data: {
                            first_name: firstName,
                            last_name: lastName
                        }
                    })
                })
                    .then(result => result.json())
                    .then(response => {
                        if (response.success) {
                            setNotifierStatus('success');
                            setTimeout(() => setNotifierStatus(''), 3000);

                            updateLocalUser({
                                firstName: firstName,
                                lastName: lastName,
                                displayName: firstName + ' ' + lastName
                            });
                        }
                        else {
                            setNotifierStatus('error');
                            setTimeout(() => setNotifierStatus(''), 5000);

                            console.error('Error saving user', response);
                            alert('Er ging iets fout bij het opslaan van uw account. Herlaad de pagina en probeer het nogmaals.');
                        }
                    })
                ;
            } catch (error) {
                console.error('Error saving user', error);
                alert('Er ging iets fout bij het opslaan van uw account. Herlaad de pagina en probeer het nogmaals.');
            }
        }, 500);

        return () => clearTimeout(timeout);
    }, [firstName, lastName, userContext, settings.apiBaseUrl, updateLocalUser]);

    function prepareFileUpload() {
        const formData = new FormData(fileUploadFormEl.current);
        if (!formData.get('new-user-avatar')) return;

        setNotifierStatus('in-progress');

        const headers = JSON.parse(JSON.stringify(userContext.fetchHeaders));
        delete headers['Content-Type'];

        fetch(settings.apiBaseUrl + 'my-account/upload-avatar', {
            method: 'POST',
            headers: headers,
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                fileUploadInputEl.current.value = '';

                if (result.success) {
                    if (result.avatar) updateLocalUser({ avatar: result.avatar });
                    setNotifierStatus('success');
                    setTimeout(() => setNotifierStatus(''), 3000);
                }
                else {
                    setNotifierStatus('error');
                    setTimeout(() => setNotifierStatus(''), 5000);

                    if (result.message === 'invalid-user-token') userContext.logout();
                    else {
                        console.error('Error uploading file', result);
                        alert('Oeps, er ging iets fout bij het uploaden van je profielfoto. Controleer of het een JPG of PNG foto is en of deze niet te groot is.');
                    }
                }
            })
        ;
    }

    return (
        <div className="my-account-general">
            <span className="my-account-general__title heading:3">Algemeen</span>

            <div className="my-account-general__inner">
                <div className="my-account-general__avatar-wrapper">
                    <div className="my-account-general__avatar-wrapper__avatar" style={{ backgroundImage: `url(${userContext.getCurrentUser().avatar})` }}></div>

                    <div className={'my-account-general__avatar-wrapper__input-wrapper' + (notifierStatus !== '' ? ' disabled' : '')}>
                        <form encType="multipart/form-data" ref={fileUploadFormEl}>
                            <button type="button" className="btn" onClick={() => { fileUploadInputEl.current.click() }}>Wijzig profielfoto</button>
                            <span className="desc">Maximaal 5MB. Alleen JPG of PNG toegestaan.</span>
                            <input name="new-user-avatar" type="file" ref={fileUploadInputEl} onChange={prepareFileUpload} />
                        </form>
                    </div>
                </div>

                <div className="my-account-general__form-wrapper">
                    <div className="input-wrapper">
                        <label>Voornaam</label>
                        <input type="text" placeholder="Voornaam" className="input input--alt" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>

                    <div className="input-wrapper">
                        <label>Achternaam</label>
                        <input type="text" placeholder="Achternaam" className="input input--alt" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                </div>
            </div>

            <div className={`notifier ${notifierStatus}`}>
                <span className="in-progress">Opslaan..</span>
                <span className="success">Gelukt!</span>
                <span className="error">Oeps.. Er ging iets niet helemaal goed, probeer het nogmaals.</span>
            </div>
        </div>
    );
}

export default MyAccountGeneral;