import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import VakcurriculumFormHeader from './components/VakcurriculumFormHeader';
import { UserContext } from '../App';

function PageNewVakcurriculumCard({ settings,  }) {
    const navigate = useNavigate();
    const userContext = useContext(UserContext);

    // Load the filter options
    useEffect(() => {
        if (userContext.fetchHeaders === null) return;

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/create-card', {
            method: 'POST',
            headers: userContext.fetchHeaders,
        })
            .then(response => response.json())
            .then(result => {
                if (result.success && result.postId) {
                    navigate('/vakcurriculum-formulier/edit/' + result.postId);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                }
            })
        ;
    }, [userContext, settings.apiBaseUrl, navigate]);

    if (! userContext.canCurrentUserDo('create_vakcur_form_posts')) {
        navigate('/vakcurriculum');
        return null;
    }

    return (<>
        <VakcurriculumFormHeader showCardsLink={true} />

        <div className="lpb-form">
            <div className="lpb-form__top-spacer"></div>

            <div className="lpb-form__inner">
                <div className="lpb-form__loading">Een ogenblik geduld,<br />je nieuwe kaart wordt aangemaakt...</div>
            </div>
        </div>
    </>);
}

export default PageNewVakcurriculumCard;