import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import VakcurriculumFormHeader from './components/VakcurriculumFormHeader';
import VakcurriculumFormDeleteCardConfirmDialog from './components/VakcurriculumFormDeleteCardConfirmDialog';
import VakcurriculumFormCard from './components/VakcurriculumFormCard';

import iconPlusBtn from './../assets/images/icon-plus.svg';
import IconInfo from './../assets/images/icon-info-light.svg';

import { UserContext } from '../App';

function PageVakcurriculumFormulier({ settings }) {
    const userContext = useContext(UserContext);

    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('all');
    const [isLoading, setIsLoading] = useState(true);
    const [userCards, setUserCards] = useState([]);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [cardIdToDelete, setCardIdToDelete] = useState(null);

    const loadUserCards = useCallback(() => {
        if (userContext.fetchHeaders === null) return;

        try {
            fetch(settings.apiBaseUrl + 'vakcurriculum-form/get-my-cards', {
                method: 'POST',
                headers: userContext.fetchHeaders
            })
                .then(response => response.json())
                .then(result => {
                    setIsLoading(false);
    
                    if (result.success) {
                        setUserCards(result.posts.map(post => {
                            return {
                                id: post.ID,
                                publishStatus: post.post_status,
                                title: post.post_title,
                                meta: post.meta
                            };
                        }));
                    }
                    else {
                        if (result.message === 'invalid-user-token' || result.data.status === 403) userContext.logout();
                        else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                    }
                })
            ;
        } catch(error) {
            console.error(error);
        }
    }, [userContext, settings.apiBaseUrl]);

    useEffect(() => loadUserCards(), [loadUserCards]);

    function addNewCard() {
        navigate('/vakcurriculum-formulier/new');
    }

    function confirmCardDelete(e, cardId) {
        e.stopPropagation();

        setCardIdToDelete(cardId);
        setShowConfirmDeleteDialog(true);
    }

    function onConfirmDeleteDialog() {
        if (userContext.fetchHeaders === null) return;

        setIsLoading(true);
        setShowConfirmDeleteDialog(false);

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/delete-card', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ postId: cardIdToDelete })
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    setCardIdToDelete(null);
                    loadUserCards();
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else if (result.message === 'invalid-post-author') {
                        navigate('/vakcurriculum-formulier');
                        console.error('Error saving post, not the owner');
                        alert('Oeps, je bent niet de eigenaar van deze kaart en kun hem daarom niet verwijderen.');
                        return;
                    }
                    else {
                        alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                    }
                }
            })
        ;
    }

    function onCancelDeleteDialog() {
        setShowConfirmDeleteDialog(false);
        setCardIdToDelete(null);
    }

    if (! userContext.canCurrentUserDo('create_vakcur_form_posts')) {
        navigate('/vakcurriculum');
        return null;
    }

    return (<>
        <VakcurriculumFormHeader />

        <div className="lpb-form">
            <div>
                <div className="lpb-form__top-spacer"></div>

                <div className="lpb-form__inner">
                    <span className="lpb-form__title has-info-text">
                        <span className="text">
                            Mijn kaarten

                            <span style={{ backgroundImage: `url(${IconInfo})` }}>
                                <div className="lpb-form-field__info-text lpb-form-field__info-text--side default-styles"><p>Dit is de tekst die uitleg geeft over dit onderdeel.</p></div>
                            </span>
                        </span>
                    </span>

                    <div className="lpb-form__card-tabs">
                        <button type="button" className={'tab' + (activeTab === 'all' ? ' active' : '')} onClick={() => setActiveTab('all')}>Alle kaarten ({userCards.length})</button>
                        <button type="button" className={'tab' + (activeTab === 'publish' ? ' active' : '')} onClick={() => setActiveTab('publish')}>Gepubliceerd ({userCards.filter(item => item.publishStatus === 'publish').length})</button>
                        <button type="button" className={'tab' + (activeTab === 'draft' ? ' active' : '')} onClick={() => setActiveTab('draft')}>Concepten ({userCards.filter(item => item.publishStatus === 'draft').length})</button>
                    </div>

                    {!isLoading ?
                        <div className="lpb-form__cards">
                            <div className="vakcur-card vakcur-card--new" style={{ backgroundImage: `url(${iconPlusBtn})` }} onClick={addNewCard}></div>
                            {userCards ? userCards.filter(card => {
                                switch (activeTab) {
                                    case 'publish': return card.publishStatus === 'publish';
                                    case 'draft': return card.publishStatus === 'draft';
                                    default: return true;
                                }
                            }).map(card => <VakcurriculumFormCard key={card.id} card={card} confirmCardDelete={confirmCardDelete} />) : null}
                        </div>
                        : null}

                    {isLoading ? <div className="lpb-form__loading lpb-form__loading--static">Laden...</div> : null}
                </div>
            </div>

            <div className="lpb-form-bottom-bar">
                <div className="lpb-form-bottom-bar__inner">
                    <div className="lpb-form-bottom-bar__left lpb-form-bottom-bar__left--small-gap">
                        <span className="lpb-form-bottom-bar__user">{userContext.getCurrentUser().displayName}</span>
                        <Link className="lpb-form-bottom-bar__link" to="/logout">Uitloggen</Link>
                    </div>
                </div>
            </div>
        </div>

        <VakcurriculumFormDeleteCardConfirmDialog show={showConfirmDeleteDialog} onConfirm={onConfirmDeleteDialog} onCancel={onCancelDeleteDialog} />
    </>);
}

export default PageVakcurriculumFormulier;