import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { UserContext } from '../../App';

function NewTopicPostBox({ topic, setTopic, settings, existingPost = null, cancelEditState = null }) {
    const userContext = useContext(UserContext);

    const [imageSelected, setImageSelected] = useState(existingPost ? existingPost.hasOwnProperty('image') : false);
    const imagePreviewEl = useRef(null);

    const [postTitle, setPostTitle] = useState(existingPost ? existingPost.name : '');
    const [postContent, setPostContent] = useState(existingPost ? existingPost.content_raw : '');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [mediaType, setMediaType] = useState(existingPost ? existingPost.media_type : 'none');

    const formEl = useRef(null);
    const titleInputEl = useRef(null);
    const contentInputEl = useRef(null);
    const fileInputEl = useRef(null);
    const videoUrlInputEl = useRef(null);

    const [videoPreviewHtml, setVideoPreviewHtml] = useState('');
    const [videoUrl, setVideoUrl] = useState(existingPost ? existingPost.embed_raw_url : '');

    function submit(e) {
        e.preventDefault();

        if (userContext.fetchHeaders === null || isSubmitting) return;

        if (titleInputEl.current.value.trim() === '') {
            alert('Geef een titel voor je bericht op.');
            titleInputEl.current.focus();
            return;
        }

        if (contentInputEl.current.value.trim() === '' && mediaType !== 'image') {
            alert('De beschrijving is verplicht wanneer er geen foto is gekozen.');
            contentInputEl.current.focus();
            return;
        }

        setIsSubmitting(true);

        // Submit the post
        const headers = JSON.parse(JSON.stringify(userContext.fetchHeaders));
        delete headers['Content-Type'];

        const formData = new FormData(formEl.current);

        if (existingPost) formData.append('postId', existingPost.id);
        formData.append('title', titleInputEl.current.value.trim());
        formData.append('content', contentInputEl.current.value.trim());
        formData.append('topicSlug', topic.slug);
        formData.append('mediaType', mediaType);

        if (mediaType === 'video') {
            formData.append('videoUrl', videoUrl);
        }

        if (mediaType !== 'image' || fileInputEl.current.files.length === 0) {
            formData.delete('new-topic-post-file');
        }

        const fetchUrl = existingPost ? settings.apiBaseUrl + 'prikbord/update-topic-post' : settings.apiBaseUrl + 'prikbord/add-topic-post';

        fetch(fetchUrl, {
            method: 'POST',
            headers: headers,
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                setIsSubmitting(false);

                if (result.success) {
                    if (existingPost) {
                        setTopic({ ...topic, posts: topic.posts.map(post => {
                            if (post.id === existingPost.id) return result.post;
                            else return post;
                        })});

                        cancelEditState();
                    }
                    else {
                        setTopic({ ...topic, posts: [result.post, ...topic.posts] });
    
                        setPostTitle('');
                        setPostContent('');
                        setMediaType('none');
                        setImageSelected(false);
    
                        fileInputEl.current.value = '';
                        contentInputEl.current.style.height = '';
                        if (videoUrlInputEl.current) videoUrlInputEl.current.value = '';
                    }
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else if (result.message === 'error-uploading-file') alert('Oeps, er ging iets fout bij het uploaden van je bestand. Controleer het bestandstype en probeer het nogmaals.');
                    else if (result.message === 'missing-content') alert('De beschrijving is verplicht wanneer er geen foto is gekozen.');
                    else alert('Oeps, er ging iets fout bij het plaatsen van je bericht. Probeer het nogmaals.');
                }
            });
    }

    function switchTopicMediaType(type) {
        setMediaType(type);

        switch (type) {
            case 'image':
                fileInputEl.current.click();
                break;

            default:
                setImageSelected(false);
                fileInputEl.current.value = '';
                break;
        }

        if (type === 'video') {
            setTimeout(() => {
                if (videoUrlInputEl.current) videoUrlInputEl.current.focus();
            }, 200);
        }
        else {
            setVideoUrl('');
            setVideoPreviewHtml('');
        }
    }

    function setTopicFile() {
        const file = fileInputEl.current.files[0];
        const size = file.size / 1024 / 1000;

        // Check the file size
        if (size > 5) {
            alert('De afbeelding mag niet groter zijn dan 5MB.');
            fileInputEl.current.value = '';
            setImageSelected(false);
            return;
        }

        // Check for file type
        if (!['image/jpg', 'image/jpeg', 'image/png'].includes(file.type)) {
            alert('Het bestand moet een JPG of PNG afbeelding zijn.');
            fileInputEl.current.value = '';
            setImageSelected(false);
            return;
        }

        setImageSelected(fileInputEl.current.value !== '');
    }

    const updateVideoPreview = useCallback(() => {
        if (mediaType !== 'video') return;

        if (videoUrl === '') {
            setVideoPreviewHtml('');
            return;
        }

        if (videoUrl && !videoUrl.startsWith('http')) {
            setVideoPreviewHtml('<span>De ingevoerde URL is ongeldig.</span>');
            return;
        }

        // Get the embed code
        fetch(settings.apiBaseUrl + 'get-embed-code', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ videoUrl })
        })
            .then(response => response.json())
            .then(result => {
                if (result.success && result.html) {
                    setVideoPreviewHtml(result.html);
                }
                else {
                    setVideoPreviewHtml('<span>De ingevoerde URL is ongeldig.</span>');
                    if (result.message === 'invalid-user-token') userContext.logout();
                }
            });
    }, [videoUrl, userContext, settings.apiBaseUrl, mediaType]);

    useEffect(() => {
        setVideoPreviewHtml('');

        const timeout = setTimeout(updateVideoPreview, 200);
        return () => clearTimeout(timeout);
    }, [videoUrl, updateVideoPreview]);

    const imgPreviewSrc = (fileInputEl.current && fileInputEl.current.files.length > 0) ? URL.createObjectURL(fileInputEl.current.files[0]) : (existingPost && existingPost.image && existingPost.image.default ? existingPost.image.default : '');

    return (<>
        { userContext.canCurrentUserDo('create_prikbord_posts') &&
            <div className={'new-topic-post-box' + (isSubmitting ? ' new-topic-post-box--loading' : '')}>
                <form ref={formEl} onSubmit={submit} encType='multipart/form-data'>
                    <div className="new-topic-post-box__top">
                        { ! existingPost && <div className="new-topic-post-box__avatar" style={{ backgroundImage: `url(${userContext.getCurrentUser().avatar})` }}></div> }

                        <div className="new-topic-post-box__input-wrapper">
                            <input ref={titleInputEl} type="text" className="new-topic-post-box__input input" placeholder="Voer een titel voor je bericht in" maxLength={80} onChange={(e) => setPostTitle(e.target.value)} value={postTitle} />
                            <textarea ref={contentInputEl} className="new-topic-post-box__input input" onChange={(e) => { setPostContent(e.target.value); e.target.style = 0; e.target.style.height = e.target.scrollHeight + 'px'; }} value={postContent} rows="1" placeholder="Typ hier je bericht"></textarea>
                            <input ref={fileInputEl} type="file" name="new-topic-post-file" onChange={setTopicFile} />
                        </div>
                    </div>

                    <div className="new-topic-post-box__bottom">
                        <div className={'new-topic-post-box__media-options' + (existingPost ? ' new-topic-post-box__media-options--no-margin' : '')}>
                            {(imageSelected === false && mediaType !== 'video') && <>
                                <button type="button" className="new-topic-post-box__media-options__image" onClick={() => switchTopicMediaType('image')}>Afbeelding toevoegen</button>
                                <button type="button" className="new-topic-post-box__media-options__video" onClick={() => switchTopicMediaType('video')}>Video toevoegen</button>
                            </>}

                            {mediaType === 'image' && imageSelected === true &&
                                <div className="new-topic-post-box__file-preview">
                                    <div className="new-topic-post-box__image-preview">
                                        <button type="button" onClick={() => { setMediaType('none'); setImageSelected(false); fileInputEl.current.value = ''; }}>Wissen</button>
                                        <img ref={imagePreviewEl} src={imgPreviewSrc} alt="Preview" />
                                    </div>
                                </div>
                            }

                            {mediaType === 'video' &&
                                <div className="new-topic-post-box__video-url-wrapper">
                                    <div className="inputs">
                                        <div className="new-topic-post-box__input-wrapper">
                                            <input ref={videoUrlInputEl} value={videoUrl} type="text" onChange={(e) => setVideoUrl(e.target.value)} className="new-topic-post-box__input input" placeholder="Plak hier een YouTube of Vimeo link" />
                                        </div>

                                        <button type="button" onClick={() => { switchTopicMediaType('none') }}>Wissen</button>
                                    </div>
                        
                                    {videoPreviewHtml !== '' &&
                                        <div className="new-topic-post-box__video-preview" dangerouslySetInnerHTML={{ __html: videoPreviewHtml }}></div>
                                    }
                                </div>
                            }
                        </div>

                        <div className="new-topic-post-box__bottom-btn-wrapper">
                            { existingPost && <button type="button" className="new-topic-post-box__submit-btn btn btn--alt" onClick={cancelEditState}>Annuleren</button> }
                            <button type="submit" className="new-topic-post-box__submit-btn btn btn--alt btn--mustard">{existingPost ? 'Opslaan' : 'Plaatsen'}</button>
                        </div>
                    </div>
                </form>
            </div>
        }
    </>);
}

export default NewTopicPostBox;