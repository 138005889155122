import React from 'react';
import { useNavigate } from 'react-router-dom';

import iconDeleteBtn from './../../assets/images/icon-delete-btn.svg';
import iconEditBtn from './../../assets/images/icon-edit-btn.svg';

function VakcurriculumFormCard({ card, confirmCardDelete }) {
    const navigate = useNavigate();

    function editCard(cardId) {
        navigate('/vakcurriculum-formulier/edit/' + cardId);
    }

    return (
        <div className="vakcur-card" onClick={() => editCard(card.id)}>
            <span className="vakcur-card__title">{card.meta.vak || card.title}</span>

            <ul className="vakcur-card__tags">
                {card.meta.traject && <li className="vakcur-card__tag">{card.meta.traject}</li>}
                {card.meta.klas && <li className="vakcur-card__tag">Klas {card.meta.klas}</li>}
                {card.meta.type_les && <li className="vakcur-card__tag">{card.meta.type_les}</li>}
                {card.meta.thema && <li className="vakcur-card__tag">{card.meta.thema}</li>}
            </ul>

            <button type="button" className="vakcur-card__delete-btn" onClick={(e) => confirmCardDelete(e, card.id)} style={{ backgroundImage: `url(${iconDeleteBtn})` }}>Verwijderen</button>
            <div className="vakcur-card__edit-btn" style={{ backgroundImage: `url(${iconEditBtn})` }}></div>
        </div>
    );
}

export default VakcurriculumFormCard;