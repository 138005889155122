import React from 'react';

function VakcurriculumFormSummaryField({ fields }) {
    function renderSummaryRow(field, appendEmpty = true) {
        let result = '';

        switch (field.type) {
            case 'input-text':
            case 'input-textarea':
                if (appendEmpty) result += `<p>${field['current-value'] || 'Niet ingevuld'}</p>`;
                else if (field['current-value']) result += `<p>${field['current-value']}</p>`;
                break;
            case 'input-text-repeat':
                if (appendEmpty) result += `<p>${field['current-value'].map(term => term.value).join(', ') || 'Niet ingevuld'}</p>`;
                else if (field['current-value']) result += `<p>${field['current-value'].map(term => term.value).join(', ')}</p>`;
                break;

            case 'taxonomy': result += `<p>${field['current-value'].map(termId => {
                const term = field.terms.find(term => term.term_id === termId);
                return term ? term.name : '';
            }).join(', ') || 'Niet ingevuld'}</p>`; break;

            default: result += ''; break;
        }

        // Loop over child fields
        if (field.children) {
            field.children.map(childField => result += renderSummaryRow(childField, false));
        }

        return result;
    }

    return (
        <div className="lpb-form-summary-field">
            <ul className="lpb-form-summary-field__fields">
                {fields.map(field => {
                    return (field.slug !== 'summary' && field.type !== 'placeholder') ? (
                        <li key={field.slug} className="lpb-form-summary-field__field field">
                            <span className="field__title">{field.title}</span>
                            <div className="field__content default-styles" dangerouslySetInnerHTML={{ __html: renderSummaryRow(field) }}></div>
                        </li>
                    ) : null;
                })}
            </ul>
        </div>
    );
}

export default VakcurriculumFormSummaryField;