import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../App';

function PageOverLeerplanboom({ settings }) {
    const userContext = useContext(UserContext);

    const [isLoading, setIsLoading] = useState(true);

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [thumbnailUrl, setThumbnailUrl] = useState('');

    useEffect(() => {
        if (userContext.fetchHeaders === null) return;

        fetch(settings.apiBaseUrl + 'get-page', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ pageSlug: 'over-de-leerplanboom' })
        })
            .then(result => result.json())
            .then(result => {
                setIsLoading(false);
                
                if (result.success) {
                    setTitle(result.page.title);
                    setContent(result.page.content);
                    setThumbnailUrl(result.page.thumbnail.large);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                }
            })
        ;
    }, [userContext, settings.apiBaseUrl]);

    return (<>
        { ! isLoading ?
            <div className="text-page">
                <div className="top-spacer"></div>

                <div className="text-page__inner">
                    <div className="text-page__img" style={{ backgroundImage: `url(${thumbnailUrl})` }}></div>

                    <div className="text-page__content-wrapper">
                        <span className="text-page__title heading:3">{title}</span>
                        <div className="text-page__content default-styles" dangerouslySetInnerHTML={{ __html: content }}></div>
                    </div>
                </div>
            </div>
        : <div className="loading-msg">Laden..</div> }
    </>);
}

export default PageOverLeerplanboom;