import React, { useEffect, useState, createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { UserContext } from '../App';

export const PrikbordContext = createContext(null);

function PrikbordWrapper({ settings }) {
    const userContext = useContext(UserContext);

    const [topics, setTopics] = useState([]);

    useEffect(() => {
        if (userContext.fetchHeaders === null) return;

        fetch(settings.apiBaseUrl + 'prikbord/get-topics', {
            method: 'POST',
            headers: userContext.fetchHeaders
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    setTopics(result.topics);
                }
                else {
                    if (result.message === 'invalid-user-token' || result.data.status === 403) userContext.logout();
                    else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                }
            })
        ;
    }, [userContext, settings.apiBaseUrl]);

    return (
        <PrikbordContext.Provider value={{ topics }}>
            <Outlet />
        </PrikbordContext.Provider>
    );
}

export default PrikbordWrapper;