import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logoIcon from './../assets/images/lpb-logo-icon.svg';
import sideBgImg from './../assets/images/lpb-side-bg.svg';
import logoFull from './../assets/images/lpb-logo-full.svg';
import visibilityIcon from './../assets/images/icon-visibility.svg';
import { UserContext } from '../App';

function PageLogin({ login, sendPasswordResetEmail }) {
    const userContext = useContext(UserContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
    const [responseMsg, setResponseMsg] = useState('');
    const [responseSuccess, setResponseSuccess] = useState(true);
    const navigate = useNavigate();

    const usernameInputEl = useRef(null);
    const emailInputEl = useRef(null);

    const redirect = useCallback(() => {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get('redirect')) window.location.href = urlParams.get('redirect');
        else navigate('/');

        return;
    }, [navigate]);

    useEffect(() => {
        if (userContext) {
            // If we are already logged in, redirect the user
            if (userContext.isLoggedIn) {
                redirect();
                return;
            }

            // Redirect to the login page of the backend
            if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
                window.location.href = userContext.getLoginUrl();
                return;
            }
        }
    }, [redirect, userContext]);

    useEffect(() => {
        if (emailInputEl.current) {
            if (forgotPasswordMode) emailInputEl.current.focus();
            else usernameInputEl.current.focus();
        }

        setShowPassword(false);
        setResponseMsg('');
        setResponseSuccess(true);
    }, [forgotPasswordMode]);

    function formSubmit(e) {
        e.preventDefault();

        if (isLoading) return;

        if (!forgotPasswordMode) {
            if (username === '') return alert('Voer eerst uw gebruikersnaam in.');
            if (password === '') return alert('Voer eerst uw wachtwoord in.');
        }
        else {
            if (email === '') return alert('Voer eerst uw e-mailadres in.');
        }

        setIsLoading(true);

        if (!forgotPasswordMode) {
            login(username, password).then((success) => {
                setIsLoading(false);

                if (success) return redirect();
                else return alert('Oeps.. De opgegeven gebruikersnaam of wachtwoord lijkt niet te kloppen. Probeer het nogmaals.')
            });
        }
        else {
            sendPasswordResetEmail(email).then((result) => {
                setIsLoading(false);
                setResponseSuccess(result.success);
                setResponseMsg(result.message);
            });
        }
    }

    return (<>
        { process.env.REACT_APP_ENVIRONMENT === 'development' && <>
            <div className="lpb-side" style={{ backgroundImage: `url(${sideBgImg})` }}>
                <Link className="lpb-side__logo" aria-label="Ga naar de beginpagina" style={{ backgroundImage: `url(${logoIcon})` }} to="/"></Link>
            </div>

            <div className="lpb-login">
                <img src={logoFull} alt="Leerplanboom" className="lpb-login__logo" />

                <span className="lpb-login__title">{forgotPasswordMode ? 'Wachtwoord vergeten?' : 'Login'}</span>

                <form className="lpb-login__form" onSubmit={e => formSubmit(e)} method="POST" disabled={isLoading}>
                    {!forgotPasswordMode ? <>
                        <div className="lpb-login__form-row">
                            <label htmlFor="username">Gebruikersnaam</label>
                            <div className="input-wrapper"><input ref={usernameInputEl} id="username" type="text" className="input" disabled={isLoading} placeholder="Gebruikersnaam invoeren" name="username" onChange={e => setUsername(e.target.value)} /></div>
                        </div>

                        <div className="lpb-login__form-row">
                            <label htmlFor="password">Wachtwoord</label>

                            <div className="input-wrapper">
                                <input id="password" type={showPassword ? 'text' : 'password'} className="input input--visibility-spacing" disabled={isLoading} placeholder="Wachtwoord invoeren" name="password" onChange={e => setPassword(e.target.value)} />
                                <button type="button" onClick={() => setShowPassword(!showPassword)} className="password-visibility-trigger" style={{ backgroundImage: `url(${visibilityIcon})` }}></button>
                            </div>
                        </div>
                    </> :
                        <div className="lpb-login__form-row">
                            <label htmlFor="email">E-mailadres</label>
                            <div className="input-wrapper"><input ref={emailInputEl} id="email" type="email" className="input" disabled={isLoading} placeholder="E-mailadres invoeren" name="email" onChange={e => setEmail(e.target.value)} /></div>
                        </div>
                    }

                    {responseMsg ? <div className={'lpb-login__response-msg' + (!responseSuccess ? ' error' : '')}>{responseMsg}</div> : null}

                    <div className="lpb-login__btn-wrapper">
                        <button className="vakcurriculum-btn lpb-login__login-btn" type="submit">{forgotPasswordMode ? 'Wachtwoord opnieuw aanvragen' : 'Inloggen'}</button>

                        {!forgotPasswordMode ? <Link className="lpb-login__forgot-password" onClick={() => setForgotPasswordMode(true)}>Wachtwoord vergeten?</Link> : null}
                        {forgotPasswordMode ? <Link className="lpb-login__forgot-password" onClick={() => setForgotPasswordMode(false)}>Inloggen</Link> : null}
                    </div>
                </form>
            </div>
        </> }

        { process.env.REACT_APP_ENVIRONMENT !== 'development' && <>
            <div className="loading-msg">Even geduld a.u.b..</div>
        </> }
    </>);
}

export default PageLogin;