import React, { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';

import VakcurriculumFormHeader from './components/VakcurriculumFormHeader';
import VakcurriculumFormFieldWrapper from './components/VakcurriculumFormFieldWrapper';
import VakcurriculumFormAddTermDialog from './components/VakcurriculumFormAddTermDialog';

import IconArrowReverse from './../assets/images/icon-arrow-reverse.svg';

import { UserContext } from '../App';

function PageEditVakcurriculumCard({ settings }) {
    const userContext = useContext(UserContext);

    const navigate = useNavigate();
    const { cardId } = useParams();
    const [currentView, setCurrentView] = useState('overview');
    const [topics, setTopics] = useState(null);
    const [activeTopicSlug, setActiveTopicSlug] = useState(null);
    const [fields, setFields] = useState([]);
    const [fieldProgress, setFieldProgress] = useState(0);
    const [currentFormFieldIndex, setCurrentFormFieldIndex] = useState(0);
    const [isSaved, setIsSaved] = useState(true);
    const [showTermDialog, setShowTermDialog] = useState(false);
    const [currentAddTermTaxonomy, setCurrentAddTermTaxonomy] = useState(null);
    const [termDialogLoading, setTermDialogLoading] = useState(false);
    const [currentFieldTitle, setCurrentFieldTitle] = useState(null);

    const fieldsUpdateCount = useRef(0);

    useEffect(() => {
        if (!fields.length) return;

        const filteredFields = fields.filter((field) => (field.section === activeTopicSlug && field['parent-field'] === null));
        setCurrentFieldTitle(filteredFields[currentFormFieldIndex]?.title);
    }, [currentFormFieldIndex, activeTopicSlug, fields]);

    useEffect(() => {
        setCurrentFormFieldIndex(0);
    }, [currentView]);

    // Load the filter options
    useEffect(() => {
        if (userContext.fetchHeaders === null || !cardId) return;

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/get-filter-options-for-post', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({ postId: parseInt(cardId) })
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    const keys = Object.keys(result.sections);
                    if (keys.length) setActiveTopicSlug(keys[0]);

                    // For each section, add a summary field to the list of fields
                    Object.keys(result.sections).map(section => {
                        result.card.fields.push({
                            title: 'Samenvatting',
                            type: 'summary',
                            slug: 'summary',
                            section: section,
                            'parent-field': null
                        });

                        return section;
                    });

                    // Add child fields to their parent fields
                    result.card.fields.map(field => {
                        if (field['parent-field'] !== null) {
                            result.card.fields.map(parentField => {
                                if (parentField.slug === field['parent-field']) {
                                    if (! parentField.children) parentField.children = [];
                                    parentField.children.push(field);
                                }

                                return parentField;
                            });
                        }

                        return field;
                    });

                    // Make sure we only have parent fields in the root of the array
                    result.card.fields = result.card.fields.filter(field => field['parent-field'] === null);

                    setTopics(result.sections);
                    setFields(result.card.fields);
                }
                else {
                    console.error('Error getting filter options');

                    if (result.message === 'invalid-user-token') userContext.logout();
                    else if (result.message === 'invalid-post') navigate('/vakcurriculum-formulier');
                    else alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                }
            })
        ;
    }, [cardId, userContext, settings.apiBaseUrl, navigate]);

    function updateField(field) {
        setFields(prevState => {
            const fieldsClone = JSON.parse(JSON.stringify(prevState));

            const result = fieldsClone.map(f => {
                if (f.slug === field.slug) f['current-value'] = field['current-value'];
                return f;
            });

            return result;
        });
    }

    const saveCard = useCallback(() => {
        if (userContext.fetchHeaders === null || !cardId || !fields.length) return;

        // Clone the fields array and remove the summary fields
        const fieldsClone = Object.values(JSON.parse(JSON.stringify(fields))).filter(field => field.type !== 'summary');

        // Add all child fields to the root of the array
        fieldsClone.forEach(field => {
            if (field.children && field.children.length) {
                field.children.forEach(childField => {
                    fieldsClone.push(childField);
                });
            }

            delete field.children;
        });

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/update-card', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({
                postId: parseInt(cardId),
                fields: fieldsClone
            })
        })
            .then(response => response.json())
            .then(result => {
                if (result.success) {
                    setIsSaved(true);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else if (result.message === 'invalid-post') navigate('/vakcurriculum-formulier');
                    else if (result.message === 'invalid-post-author') {
                        navigate('/vakcurriculum-formulier');
                        console.error('Error saving post, not the owner');
                        alert('Oeps, je bent niet de eigenaar van deze kaart en kun hem daarom niet aanpassen.');
                        return;
                    }
                    else {
                        console.error('Error saving post');
                        alert('Oeps, er ging iets fout bij het laden van de pagina. Probeer het nogmaals.');
                    }
                }
            });
    }, [cardId, userContext, fields, settings.apiBaseUrl, navigate]);

    useEffect(() => {
        fieldsUpdateCount.current++;
        if (fieldsUpdateCount.current <= 2) return;

        setIsSaved(false);

        const timeout = setTimeout(() => saveCard(), 500);
        return () => clearTimeout(timeout);
    }, [fields, saveCard]);

    useEffect(() => {
        const fieldCount = fields.length;
        let completedFieldCount = 0;

        if (fieldCount === 0) return;

        fields.map(field => {
            if (field['current-value']?.length > 0) completedFieldCount++;
            return null;
        });

        setFieldProgress(Math.floor(completedFieldCount / fieldCount * 100));
    }, [fields]);

    function showAddTermDialog(taxonomy) {
        setCurrentAddTermTaxonomy(taxonomy);
        setShowTermDialog(true);
    }

    function addTerm(termName) {
        if (userContext.fetchHeaders === null) return;
        if (currentAddTermTaxonomy === null) {
            alert('Oeps, er ging iets fout waar jij niets aan kon doen.. Herlaad de pagina en probeer het nogmaals.');
            return;
        }

        termName = termName.trim();

        if (termName === '') {
            alert('Geen een titel op');
            return;
        }

        setTermDialogLoading(true);

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/add-term', {
            method: 'POST',
            headers: userContext.fetchHeaders,
            body: JSON.stringify({
                termName: termName,
                taxonomySlug: currentAddTermTaxonomy
            })
        })
            .then(response => response.json())
            .then(result => {
                setTermDialogLoading(false);
                setShowTermDialog(false);

                if (result.success) {
                    const fieldsClone = JSON.parse(JSON.stringify(fields));

                    fieldsClone.map(field => {
                        if (field.type === 'taxonomy' && field.taxonomy === currentAddTermTaxonomy) field.terms.push(result.term);
                        return field;
                    });

                    setFields(fieldsClone);
                    setCurrentAddTermTaxonomy(null);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else {
                        console.error('Error creating term');
                        alert('Oeps, er ging iets fout bij het opslaan. Probeer het nogmaals.');
                    }
                }
            })
        ;
    }

    function addZero(number) {
        if (number >= 10) return '' + number;
        return '0' + number;
    }

    function uploadFile(formData, callback) {
        const headers = JSON.parse(JSON.stringify(userContext.fetchHeaders));
        delete headers['Content-Type'];

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/upload-file', {
            method: 'POST',
            headers: headers,
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                callback();

                if (result.success) {
                    const fieldsClone = JSON.parse(JSON.stringify(fields)).map(field => {
                        if (field.slug === formData.get('field_slug')) field['allows-file-upload']['existing_file_name'] = result['file_title'];
                        return field;
                    });

                    setFields(fieldsClone);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else {
                        console.error('Error uploading file');
                        alert('Oeps, er ging iets fout bij het uploaden van het bestand. Controleer of het een JPG of PNG foto is en of deze niet te groot is.');
                    }
                }
            })
        ;
    }

    function deleteFile(formData, callback) {
        const headers = JSON.parse(JSON.stringify(userContext.fetchHeaders));
        delete headers['Content-Type'];

        fetch(settings.apiBaseUrl + 'vakcurriculum-form/delete-file', {
            method: 'POST',
            headers: headers,
            body: formData
        })
            .then(response => response.json())
            .then(result => {
                callback();

                if (result.success) {
                    const fieldsClone = JSON.parse(JSON.stringify(fields)).map(field => {
                        if (field.slug === formData.get('field_slug')) field['allows-file-upload']['existing_file_name'] = null;
                        return field;
                    });

                    setFields(fieldsClone);
                }
                else {
                    if (result.message === 'invalid-user-token') userContext.logout();
                    else {
                        console.error('Error deleting file');
                        alert('Oeps, er ging iets fout bij het verwijderen van het bestand. Probeer het nogmaals.');
                    }
                }
            })
        ;
    }

    if (! userContext.canCurrentUserDo('create_vakcur_form_posts')) {
        navigate('/vakcurriculum');
        return null;
    }

    return (<>
        <VakcurriculumFormHeader showCardsLink={true} />

        <div className="lpb-form">
            <div>
                <div className="lpb-form__top-spacer"></div>

                <div className="lpb-form__inner">
                    {topics !== null ?
                        <>
                            {currentView !== 'overview' ? <>
                                <button style={{ backgroundImage: `url(${IconArrowReverse})` }} className="lpb-form__back-btn" onClick={() => setCurrentView('overview')}>Terug</button>
                                <VakcurriculumFormFieldWrapper fields={fields.filter((field) => field.section === currentView)} allFields={fields} updateField={updateField} showAddTermDialog={showAddTermDialog} currentFormFieldIndex={currentFormFieldIndex} setCurrentFormFieldIndex={setCurrentFormFieldIndex} goBack={() => setCurrentView('overview')} uploadFile={uploadFile} deleteFile={deleteFile} cardId={cardId} />
                            </> :
                                <ul className="lpb-form__topics">
                                    {Object.keys(topics).map(topicSlug => (<li className={'lpb-form__topic' + (activeTopicSlug === topicSlug ? ' lpb-form__topic--active' : '')} onMouseOver={() => setActiveTopicSlug(topicSlug)} key={topics[topicSlug].slug} onClick={() => setCurrentView(topics[topicSlug].slug)}><button>{topics[topicSlug].title}</button></li>))}
                                </ul>
                            }
                        </>
                    : <div className="lpb-form__loading">Je kaart wordt geladen...</div> }
                </div>

                {currentView !== 'overview' && fieldsUpdateCount.current > 2 && <div className="lpb-form__save-status-wrapper">
                    <button className={'lpb-form__save-status-btn vakcurriculum-btn vakcurriculum-btn--simple vakcurriculum-btn--grey' + (isSaved ? ' show' : '')}>Kaart is opgeslagen</button>
                    <button className={'lpb-form__save-status-btn vakcurriculum-btn vakcurriculum-btn--simple vakcurriculum-btn--grey' + (!isSaved ? ' show' : '')}>Kaart wordt opgeslagen...</button>
                </div>}
            </div>

            {currentView === 'overview' ?
                <div className="lpb-form-bottom-bar">
                    <div className="lpb-form-bottom-bar__inner">
                        <div className="lpb-form-bottom-bar__left lpb-form-bottom-bar__left--small-gap">
                            <span className="lpb-form-bottom-bar__user">{userContext.getCurrentUser().displayName}</span>
                            <Link className="lpb-form-bottom-bar__link" to="/logout">Uitloggen</Link>
                        </div>

                        <div className="lpb-form-bottom-bar__right">
                            <div className="lpb-form-bottom-bar__progress-wrapper">
                                <div className="progress"><div style={{ width: fieldProgress + '%' }}></div></div>
                                <span className={'text' + (fieldProgress === 100 ? ' completed' : '')}>Voltooid {fieldProgress}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <div className="lpb-form-bottom-bar">
                    <div className="lpb-form-bottom-bar__inner">
                        <div className="lpb-form-bottom-bar__left">
                            <div className="lpb-form-bottom-bar__steps">
                                <span>{addZero(currentFormFieldIndex + 1)}</span>
                                <span>/{addZero(fields.filter((field) => field.section === activeTopicSlug).length)}</span>
                            </div>

                            <div className="lpb-form-bottom-bar__section">
                                <span>{topics[activeTopicSlug].title} /</span>
                                <span>{currentFieldTitle}</span>
                            </div>
                        </div>
                        <div className="lpb-form-bottom-bar__right">
                            <div className="lpb-form-bottom-bar__progress-wrapper">
                                <div className="progress"><div style={{ width: fieldProgress + '%' }}></div></div>
                                <span className={'text' + (fieldProgress === 100 ? ' completed' : '')}>Voltooid {fieldProgress}%</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

        <VakcurriculumFormAddTermDialog show={showTermDialog} setShow={setShowTermDialog} addTerm={addTerm} loading={termDialogLoading} />
    </>);
}

export default PageEditVakcurriculumCard;