import React, { useState, useEffect, useCallback, useRef } from 'react';

// Options should be an arrao with objects in this format: { title: String, slug: String }
function CustomSelect({ placeholderText, options, selectedOptionSlug = null, setSelectedOptionSlug = null, btnClasses = '', compact = false }) {
    const wrapperEl = useRef(null);
    const [showSelect, setShowSelect] = useState(false);

    const handleOutsideClick = useCallback(e => {
        if (wrapperEl.current && !wrapperEl.current.contains(e.target)) {
            setShowSelect(false);
        }
    }, []);

    const handleKeyUp = useCallback(e => {
        if (e.key.toLowerCase() === 'escape') setShowSelect(false);
    }, []);

    useEffect(() => {
        document.addEventListener('mouseup', handleOutsideClick);
        window.addEventListener('keyup', handleKeyUp);

        return () => document.removeEventListener('mouseup', handleOutsideClick);
    }, [handleOutsideClick, handleKeyUp]);

    return (<>
        { !! options.length > 0 &&
            <div ref={wrapperEl} className={'custom-select' + (showSelect ? ' open' : '') + (compact ? ' custom-select--compact' : '')}>
                { !! options.length > 0 && <button className={'custom-select__btn btn btn--arrow-inline btn--arrow-inline-down ' + (selectedOptionSlug === null ? ' placeholder' : '') + btnClasses} onClick={() => setShowSelect(!showSelect)}>{selectedOptionSlug === null ? placeholderText : options.find(option => option.slug === selectedOptionSlug).title}</button>}

                <ul className="custom-select__options">
                    { options.map((option, i) => (<li key={'' + Math.random() + Math.random()}><button className={'custom-select__option btn btn--beige'} onClick={() => { setSelectedOptionSlug(option.slug); setShowSelect(false) }}>{option.title}</button></li>)) }
                </ul>
            </div>
        }
    </>);
}

export default CustomSelect;