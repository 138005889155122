function TopicComment({ comment }) {
    return (
        <div className="topic-post__comment-box comment-box">
            <div className="comment-box__avatar" style={{ backgroundImage: `url(${comment.author.avatar})` }}></div>
            <div className="comment-box__content">
                <span className="comment-box__name">{comment.author.name}</span>
                <div className="comment-box__comment default-styles"><p>{comment.comment}</p></div>
            </div>
        </div>
    );
}

export default TopicComment;