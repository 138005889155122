import React, { useEffect, useCallback } from 'react';
import logo from './../assets/images/lpb-logo-full.svg';
import { Link } from 'react-router-dom';

function MainMenu({ showMenu, setShowMainMenu }) {
    const keyUpListener = useCallback(e => {
        if (e.key.toLowerCase() === 'escape') {
            setShowMainMenu(false);
        }
    }, [setShowMainMenu]);

    useEffect(() => {
        window.addEventListener('keyup', keyUpListener);
        return () => window.removeEventListener('keyup', keyUpListener);
    }, [keyUpListener]);

    function hide() {
        setShowMainMenu(false);
    }

    return (
        <nav className={'main-menu' + (showMenu ? ' show' : '')}>
            <img src={logo} alt="De Leerplanboom" className="main-menu__top-logo" />
            <button type="button" className="main-menu__close-btn" onClick={hide}>Sluiten</button>

            <div className="main-menu__inner">
                <div>
                    <ul className="main-menu__list">
                        <li className="vakcurriculum heading:1"><Link onClick={hide} to="/">Vakcurriculum</Link></li>
                        {/* <li className="menskunde heading:1"><Link onClick={hide} to="/">Menskunde</Link></li> */}
                        {/* <li className="vs-pedagogiek heading:1"><Link onClick={hide} to="/">VS-Pedagogiek</Link></li> */}
                        {/* <li className="ontwikkelingsmoment heading:1"><Link onClick={hide} to="/">Ontwikkelingsmoment</Link></li> */}
                        {/* <li className="methodisch-disactisch heading:1"><Link onClick={hide} to="/">Methodisch-didactisch</Link></li> */}
                        <li className="prikbord heading:1"><Link onClick={hide} to="/prikbord">Prikbord</Link></li>
                    </ul>

                    <ul className="main-menu__side">
                        <li><Link onClick={hide} to="/mijn-account">Mijn account</Link></li>
                        <li><Link onClick={hide} to="/over-de-leerplanboom">Over de Leerplanboom</Link></li>
                        {/* <li><Link onClick={hide} to="/support">Support</Link></li> */}
                        {/* <li><Link onClick={hide} to="/faq">F.A.Q.</Link></li> */}
                        {/* <li><Link onClick={hide} to="/contact">Contact</Link></li> */}
                        <li><Link onClick={hide} to="/logout">Uitloggen</Link></li>
                    </ul>
                </div>
            </div>

            <ul className="main-menu__bottom">
                <li><Link onClick={hide} to="/privacy-statement">Privacy Statement</Link></li>
                <li><Link onClick={hide} to="/terms-conditions">Terms & Conditions</Link></li>
            </ul>
        </nav>
    );
}

export default MainMenu;