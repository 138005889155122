import React, { useState, useEffect, useCallback, useContext } from 'react';
import { VakcurContext } from './VakcurLayoutWrapper';

function VakcurFilter({ isSearch = false }) {
    const vakcurContext = useContext(VakcurContext);

    const getActiveFilterCount = isSearch ? vakcurContext.getActiveSearchFilterCount : vakcurContext.getActiveFilterCount;

    const [show, _setShow] = useState(false);
    const [insideFilter, setInsideFilter] = useState('');

    const activeFilters = isSearch ? vakcurContext.activeSearchFilters : vakcurContext.activeFilters;

    const currentActiveVak = activeFilters.vak !== null ? vakcurContext.vakken.find(v => v.slug === activeFilters.vak) : null;
    const currentActiveTraject = activeFilters.traject !== null ? vakcurContext.trajecten.find(t => t.slug === activeFilters.traject) : null;
    const currentActiveLestype = activeFilters.lestype !== null ? vakcurContext.lestypen.find(l => l.slug === activeFilters.lestype) : null;

    const currentActiveTijdvak = activeFilters.tijdvak !== null ? vakcurContext.tijdvakken.find(t => t.slug + '' === activeFilters.tijdvak) : null;
    const currentActiveVakoverstijging = activeFilters.vakoverstijging !== null ? vakcurContext.vakoverstijgingen.find(v => v.slug === activeFilters.vakoverstijging) : null;
    const currentActiveHuiswerk = activeFilters.huiswerk !== null ? vakcurContext.huiswerk.find(h => h.slug === activeFilters.huiswerk) : null;
    const currentActiveLeerfocus = activeFilters.leerfocus !== null ? vakcurContext.leerfocus.find(l => l.slug === activeFilters.leerfocus) : null;
    const currentActiveAfsluiting = activeFilters.afsluiting !== null ? vakcurContext.afsluitingen.find(a => a.slug === activeFilters.afsluiting) : null;
    const currentActivePta = activeFilters.pta !== null ? vakcurContext.pta.find(p => p.slug === activeFilters.pta) : null;
    const currentActivePortfolio = activeFilters.portfolio !== null ? vakcurContext.portfolio.find(p => p.slug === activeFilters.portfolio) : null;

    const insideFilterOptions = getInsideFilterOptions();
    const activeInsideFilterSlugs = getActiveInsideFilterSlugs();

    const keyUp = useCallback(e => {
        if (e.key.toLowerCase() === 'escape') hide();
    }, []);

    function toggleVisibility() {
        if (show) hide();
        else _setShow(true);
    }

    function hide() {
        setInsideFilter('');
        _setShow(false);
    }

    function clearFilters() {
        if (! isSearch) {
            vakcurContext.helperFunctions.nav('/');
        }

        vakcurContext.helperFunctions.setKlasFilter(null, isSearch);
        vakcurContext.helperFunctions.setVakFilter(null, isSearch);
        vakcurContext.helperFunctions.setTrajectFilter(null, isSearch);
        vakcurContext.helperFunctions.setLestypeFilter(null, isSearch);
        vakcurContext.helperFunctions.setTijdvakFilter(null, isSearch);
        vakcurContext.helperFunctions.setVakoverstijgingFilter(null, isSearch);
        vakcurContext.helperFunctions.setHuiswerkFilter(null, isSearch);
        vakcurContext.helperFunctions.setLeerfocusFilter(null, isSearch);
        vakcurContext.helperFunctions.setAfsluitingFilter(null, isSearch);
        vakcurContext.helperFunctions.setPtaFilter(null, isSearch);
        vakcurContext.helperFunctions.setPortfolioFilter(null, isSearch);
    }

    function getInsideFilterOptions() {
        switch (insideFilter) {
            case 'klas': return vakcurContext.klassen;
            case 'vak': return vakcurContext.vakken;
            case 'traject': return vakcurContext.trajecten;
            case 'lestype': return vakcurContext.lestypen;

            case 'tijdvak': return vakcurContext.tijdvakken;
            case 'vakoverstijging': return vakcurContext.vakoverstijgingen;
            case 'huiswerk': return vakcurContext.huiswerk;
            case 'leerfocus': return vakcurContext.leerfocus;
            case 'afsluiting': return vakcurContext.afsluitingen;
            case 'pta': return vakcurContext.pta;
            case 'portfolio': return vakcurContext.portfolio;

            default: return [];
        }
    }

    function getActiveInsideFilterSlugs() {
        switch (insideFilter) {
            case 'klas': return [activeFilters.klas];
            case 'vak': return [activeFilters.vak];
            case 'traject': return [activeFilters.traject];
            case 'lestype': return [activeFilters.lestype];

            case 'tijdvak': return [activeFilters.tijdvak];
            case 'vakoverstijging': return [activeFilters.vakoverstijging];
            case 'huiswerk': return [activeFilters.huiswerk];
            case 'leerfocus': return [activeFilters.leerfocus];
            case 'afsluiting': return [activeFilters.afsluiting];
            case 'pta': return [activeFilters.pta];
            case 'portfolio': return [activeFilters.portfolio];

            default: return [];
        }
    }

    function setCurrentInsideFilter(slug) {
        switch (insideFilter) {
            case 'klas':
                if (activeFilters.klas === slug) vakcurContext.helperFunctions.setKlasFilter(null, isSearch);
                else vakcurContext.helperFunctions.setKlasFilter(slug, isSearch);
                break;
            case 'vak':
                if (activeFilters.vak === slug) vakcurContext.helperFunctions.setVakFilter(null, isSearch);
                else vakcurContext.helperFunctions.setVakFilter(slug, isSearch);
                break;
            case 'traject':
                if (activeFilters.traject === slug) vakcurContext.helperFunctions.setTrajectFilter(null, isSearch);
                else vakcurContext.helperFunctions.setTrajectFilter(slug, isSearch);
                break;
            case 'lestype':
                if (activeFilters.lestype === slug) vakcurContext.helperFunctions.setLestypeFilter(null, isSearch);
                else vakcurContext.helperFunctions.setLestypeFilter(slug, isSearch);
                break;

            case 'tijdvak':
                if (activeFilters.tijdvak === slug) vakcurContext.helperFunctions.setTijdvakFilter(null, isSearch);
                else vakcurContext.helperFunctions.setTijdvakFilter(slug, isSearch);
                break;
            case 'vakoverstijging':
                if (activeFilters.vakoverstijging === slug) vakcurContext.helperFunctions.setVakoverstijgingFilter(null, isSearch);
                else vakcurContext.helperFunctions.setVakoverstijgingFilter(slug, isSearch);
                break;
            case 'huiswerk':
                if (activeFilters.huiswerk === slug) vakcurContext.helperFunctions.setHuiswerkFilter(null, isSearch);
                else vakcurContext.helperFunctions.setHuiswerkFilter(slug, isSearch);
                break;
            case 'leerfocus':
                if (activeFilters.leerfocus === slug) vakcurContext.helperFunctions.setLeerfocusFilter(null, isSearch);
                else vakcurContext.helperFunctions.setLeerfocusFilter(slug, isSearch);
                break;
            case 'afsluiting':
                if (activeFilters.afsluiting === slug) vakcurContext.helperFunctions.setAfsluitingFilter(null, isSearch);
                else vakcurContext.helperFunctions.setAfsluitingFilter(slug, isSearch);
                break;
            case 'pta':
                if (activeFilters.pta === slug) vakcurContext.helperFunctions.setPtaFilter(null, isSearch);
                else vakcurContext.helperFunctions.setPtaFilter(slug, isSearch);
                break;
            case 'portfolio':
                if (activeFilters.portfolio === slug) vakcurContext.helperFunctions.setPortfolioFilter(null, isSearch);
                else vakcurContext.helperFunctions.setPortfolioFilter(slug, isSearch);
                break;

            default: return;
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', keyUp);
        return () => window.removeEventListener('keyup', keyUp);
    }, [keyUp]);

    return (<>
        <div className={`vakcur-filter-wrapper${isSearch ? ' vakcur-filter-wrapper--static' : ''}`}>
            <button type="button" className={`vakcur-filter-btn${show ? ' active' : ''}${isSearch ? ' vakcur-filter-btn--static' : ''}`} onClick={toggleVisibility}>
                {!!getActiveFilterCount() > 0 && <span className="vakcur-filter-btn__counter">{getActiveFilterCount()}</span>}
            </button>

            <div className={'vakcur-filter-modal' + (show ? ' show' : '') + (isSearch ? ' vakcur-filter-modal--static' : '')}>
                <div className="vakcur-filter-modal__bg" onClick={hide}></div>

                <div className="vakcur-filter-modal__inner">
                    <div className="vakcur-filter-modal__top">
                        {insideFilter === '' ? <span>Filters</span> : <button type="button" onClick={() => setInsideFilter('')}>{insideFilter}</button>}
                    </div>

                    <div className="vakcur-filter-modal__content-wrapper">
                        {insideFilter === '' && <>
                            <div className="vakcur-filter-modal__filter-list">
                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('klas')}>
                                    <span className="vakcur-filter-modal__filter__title">Klas</span>
                                    <span className="vakcur-filter-modal__filter__content">{activeFilters.klas}</span>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('vak')}>
                                    <span className="vakcur-filter-modal__filter__title">Vak</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveVak && currentActiveVak.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('traject')}>
                                    <span className="vakcur-filter-modal__filter__title">Traject</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveTraject && currentActiveTraject.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('lestype')}>
                                    <span className="vakcur-filter-modal__filter__title">Lestype</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveLestype && currentActiveLestype.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('tijdvak')}>
                                    <span className="vakcur-filter-modal__filter__title">Tijdvak</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveTijdvak && currentActiveTijdvak.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('vakoverstijging')}>
                                    <span className="vakcur-filter-modal__filter__title">Vakoverstijging</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveVakoverstijging && currentActiveVakoverstijging.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('huiswerk')}>
                                    <span className="vakcur-filter-modal__filter__title">Huiswerk</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveHuiswerk && currentActiveHuiswerk.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('leerfocus')}>
                                    <span className="vakcur-filter-modal__filter__title">Leerfocus</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveLeerfocus && currentActiveLeerfocus.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('afsluiting')}>
                                    <span className="vakcur-filter-modal__filter__title">Afsluiting</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActiveAfsluiting && currentActiveAfsluiting.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('pta')}>
                                    <span className="vakcur-filter-modal__filter__title">PTA</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActivePta && currentActivePta.name}</span>
                                    </div>
                                </button>

                                <button type="button" className="vakcur-filter-modal__filter" onClick={() => setInsideFilter('portfolio')}>
                                    <span className="vakcur-filter-modal__filter__title">Portfolio</span>
                                    <div className="vakcur-filter-modal__filter__right">
                                        <span className="vakcur-filter-modal__filter__content">{!!currentActivePortfolio && currentActivePortfolio.name}</span>
                                    </div>
                                </button>
                            </div>
                        </>}

                        {insideFilter !== '' && <>
                            <div className="vakcur-filter-modal__filter-options">
                                {insideFilterOptions.map(option => (
                                    <button key={`inside-filter-option-${option.slug}`} className={'vakcur-filter-modal__filter-option' + (activeInsideFilterSlugs.includes(option.slug) ? ' active' : '')} type="button" onClick={() => setCurrentInsideFilter(option.slug)}>
                                        <div className="vakcur-filter-modal__filter-option__indicator"></div>
                                        <span className="vakcur-filter-modal__filter-option__title">{option.human_name || option.name}</span>
                                    </button>
                                ))}
                            </div>
                        </>}
                    </div>

                    <div className="vakcur-filter-modal__bottom">
                        <button type="button" className="btn" onClick={clearFilters}>Wis filters</button>
                        <button type="button" className="btn btn--mustard" onClick={hide}>Bekijk resultaten</button>
                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default VakcurFilter;