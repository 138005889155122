import React from 'react';
import { Outlet, Link, useLocation } from 'react-router-dom';

function MyAccountLayout() {
    const location = useLocation();

    return (<>
        <div className="my-account">
            <div className="top-spacer"></div>

            <div className="my-account__inner">
                <div className="my-account__left">
                    <span className="my-account__side-title">Account instellingen</span>

                    <ul className="my-account__menu">
                        <li><Link className={'heading:3' + (location.pathname === '/mijn-account' ? ' active' : '')} to="/mijn-account">Account</Link></li>
                    </ul>
                </div>

                <div className="my-account__right">
                    <Outlet />
                </div>
            </div>
        </div>
    </>);
}

export default MyAccountLayout;