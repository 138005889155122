import React from 'react';
import VakcurriculumFormField from './VakcurriculumFormField';
import VakcurriculumFormSummaryField from './VakcurriculumFormSummaryField';

function VakcurriculumFormFieldWrapper({ fields, updateField, showAddTermDialog, currentFormFieldIndex, setCurrentFormFieldIndex, goBack, uploadFile, deleteFile, cardId, allFields }) {
    function prevField() {
        if (currentFormFieldIndex > 0) {
            setCurrentFormFieldIndex(currentFormFieldIndex - 1);
        }
    }

    function nextField() {
        if (fields.length > currentFormFieldIndex + 1) setCurrentFormFieldIndex(currentFormFieldIndex + 1);
        else goBack();
    }

    return (<div className="lpb-form__field-wrapper">
        <div className="lpb-form__fields">
            { fields[currentFormFieldIndex].type === 'summary' && <VakcurriculumFormSummaryField fields={fields} /> }
            { fields[currentFormFieldIndex].type !== 'summary' && <VakcurriculumFormField field={fields[currentFormFieldIndex]} updateField={updateField} showAddTermDialog={showAddTermDialog} uploadFile={uploadFile} deleteFile={deleteFile} cardId={cardId} isChild={false} allFields={allFields} /> }
        </div>

        {fields.length > 1 &&
            <div className="lpb-form__nav-btn-wrapper">
                <button type="button" onClick={prevField} disabled={currentFormFieldIndex === 0} className="vakcurriculum-btn">Vorige</button>
                <button type="button" onClick={nextField} className="vakcurriculum-btn">{currentFormFieldIndex < fields.length - 1 ? 'Volgende' : 'Voltooien'}</button>
            </div>
        }
    </div>);
}

export default VakcurriculumFormFieldWrapper;