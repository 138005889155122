export function getCurrentUser(addLoginToken = false) {
    const user = JSON.parse(localStorage.getItem('lpb_user'));

    if (! user) return null;
    if (! addLoginToken) delete user.loginToken;

    user.id = parseInt(user.id);

    return user;
}

export function updateLocalUser(user) {
    const existingUser = getCurrentUser(true);

    localStorage.setItem('lpb_user', JSON.stringify({
        ...existingUser,
        ...user
    }));
}

export function canCurrentUserDo(permission) {
    const currentUser = getCurrentUser();
    const permissionValue = getUserPermissionValue(permission);

    if (! permissionValue) return false;

    return !! currentUser.permissions[permission];
}

export function getUserPermissionValue(permission) {
    const currentUser = getCurrentUser();

    if (typeof currentUser.permissions === 'undefined') return false;
    if (typeof currentUser.permissions[permission] === 'undefined') return false;

    return currentUser.permissions[permission];
}

export function getLoginToken() {
    const user = getCurrentUser(true);
    if (! user) return null;

    return user.loginToken;
}