import React from 'react';

function VakcurriculumFormDeleteCardConfirmDialog({ show, onConfirm, onCancel }) {
    return (
        <div className={'lpb-dialog' + (show ? ' show' : '')}>
            <div className="lpb-dialog__inner">
                <span className="lpb-dialog__title">Kaart verwijderen?</span>
                <div className="lpb-dialog__desc default-styles"><p>Weet je zeker dat je deze kaart wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.</p></div>

                <div className="lpb-dialog__btn-wrapper">
                    <button type="button" className="vakcurriculum-btn vakcurriculum-btn--full-white" onClick={onCancel}>Annuleren</button>
                    <button type="button" className="vakcurriculum-btn vakcurriculum-btn--full-mustard" onClick={onConfirm}>Verwijderen</button>
                </div>
            </div>
        </div>
    );
}

export default VakcurriculumFormDeleteCardConfirmDialog;