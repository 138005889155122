import { Link } from "react-router-dom";

function Page404() {
    return (
        <div>
            <header>
                <p>Pagina niet gevonden</p>
                <p><Link to={'/'}>Terug naar home</Link></p>
            </header>
        </div>
    );
}

export default Page404;
