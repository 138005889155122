import React, { useEffect, useState, useCallback } from 'react';

import lpbLogo from './../assets/images/lpb-logo-full.svg';
import { Link } from 'react-router-dom';

function BottomLogo() {
    const scrollOffset = 0;

    const [showLogo, setShowLogo] = React.useState(true);
    const [y, setY] = useState(window.scrollY);

    const updatePosition = useCallback(() => {
        const directionUp = window.scrollY < y;

        if (directionUp) setShowLogo(true);
        else setShowLogo(window.scrollY <= scrollOffset);

        setY(window.scrollY);
    }, [y]);

    useEffect(() => {
        window.addEventListener('scroll', updatePosition);
        return () => window.removeEventListener('scroll', updatePosition);
    }, [updatePosition]);

    return (
        <div className={'bottom-logo' + (! showLogo ? ' hide' : '')}>
            <Link to="/" className="bottom-logo__logo"><img src={lpbLogo} alt="Logo" /></Link>
        </div>
    );
}

export default BottomLogo;