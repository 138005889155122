import React, { useEffect, useLayoutEffect, useContext, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import gsap from 'gsap';

import { VakcurContext } from './../components/VakcurLayoutWrapper';

import TrajectSelectModal from './../components/TrajectSelectModal';

import setFullscreenVideoAspectRatio from '../helpers/VideoHelper';
import SideYearNav from '../components/SideYearNav';

const bgVideos = require.context('../assets/videos/vaktak', true);
const vakVideoMap = {};
bgVideos.keys().map(key => vakVideoMap[key.replace('./', '').replace('.mp4', '')] = bgVideos(key));

// TODO: We need to combine the free spots fro each vak (0,1,2) with the videos above

function PageVak() {
    const navigate = useNavigate();
    const params = useParams();
    const vakcurContext = useContext(VakcurContext);

    const [showAltImages, setShowAltImages] = useState(false);
    const [videoUrl, setVideoUrl] = useState(null);
    const videoEl = useRef(null);
    const altImgWrapper = useRef(null);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showTrajectSelect, setShowTrajectSelect] = useState(false);

    const vakSlug = params.vak || null;
    const currentVakcurVak = vakcurContext.vakken.find(vak => vak.slug === vakSlug);

    const currentVakcurKlas = vakcurContext.klassen.find(klas => parseInt(klas.slug) === parseInt(vakcurContext.activeFilters.klas));
    const currentCardsFiltered = getCurrentCardsArrayFiltered();

    function resize() {
        if (videoEl.current !== null) setFullscreenVideoAspectRatio(videoEl.current, 16 / 9);
        if (altImgWrapper.current !== null) setFullscreenVideoAspectRatio(altImgWrapper.current, 16 / 9);
    }

    resize();

    function trajectSelected(trajectSlug) {
        if (trajectSlug !== null) {
            vakcurContext.helperFunctions.setTrajectFilter(trajectSlug);
        }
        else {
            setShowTrajectSelect(false);
            navigate('/vakcurriculum');
        }
    }

    function getCurrentCardsArrayFiltered() {
        if (vakcurContext.activeFilters.vak === null) return [];

        const cards = [];
        const klas = vakcurContext.klassen.find(klas => parseInt(klas.slug) === parseInt(vakcurContext.activeFilters.klas));

        if (klas) cards.push(...Object.values(klas.kaarten));
        else vakcurContext.klassen.map(klas => cards.push(...Object.values(klas.kaarten)));

        return cards.filter(card => {
            if (!card.vakken.includes(vakSlug)) return false;
            if ((!!klas && !!klas.trajecten.length) && vakcurContext.activeFilters.traject !== null && !card.trajecten.includes(vakcurContext.activeFilters.traject)) return false;
            if (vakcurContext.activeFilters.lestype !== null && !card.lestypen.includes(vakcurContext.activeFilters.lestype)) return false;

            if (vakcurContext.activeFilters.tijdvak !== null && !card.tijdvakken.includes(vakcurContext.activeFilters.tijdvak)) return false;
            if (vakcurContext.activeFilters.vakoverstijging !== null && !card.vakoverstijgingen.includes(vakcurContext.activeFilters.vakoverstijging)) return false;
            if (vakcurContext.activeFilters.huiswerk !== null && !card.huiswerk.includes(vakcurContext.activeFilters.huiswerk)) return false;
            if (vakcurContext.activeFilters.leerfocus !== null && !card.leerfocus.includes(vakcurContext.activeFilters.leerfocus)) return false;
            if (vakcurContext.activeFilters.afsluiting !== null && !card.afsluitingen.includes(vakcurContext.activeFilters.afsluiting)) return false;
            if (vakcurContext.activeFilters.pta !== null && !card.pta.includes(vakcurContext.activeFilters.pta)) return false;
            if (vakcurContext.activeFilters.portfolio !== null && !card.portfolio.includes(vakcurContext.activeFilters.portfolio)) return false;

            return true;
        });
    }

    function goToCard(slug) {
        navigate(`/vakcurriculum/kaart/${slug}`);
    }

    function getCardTitle(card) {
        if (card.thema) return card.thema;
        if (card.tijdvakken_format.length) {
            if (!vakcurContext.activeFilters.klas && !!card.klassen.length) return `Klas ${card.klassen.join('/')} tijdvak ${card.tijdvakken_format.join(', ')}`;
            return `Tijdvak ${card.tijdvakken_format.join(', ')}`;
        }
        if (card.vakken_format.length) return card.vakken_format.join(', ');

        return card.name;
    }

    // If we visit this page but active filter is not, set active filter to vakSlug
    // useEffect(() => {
        // if (vakSlug !== null && vakcurContext.activeFilters.vak === null) {
            // vakcurContext.helperFunctions.setVakFilter(vakSlug);
        // }
    // }, [vakSlug, vakcurContext.activeFilters.vak, vakcurContext.helperFunctions]);

    useEffect(() => {
        if (vakSlug === null) return null;

        if (vakVideoMap.hasOwnProperty(vakSlug)) return setVideoUrl(vakVideoMap[vakSlug]);
        return setVideoUrl(vakVideoMap['onbekend']);
    }, [vakSlug]);

    useEffect(() => {
        if (vakcurContext.activeFilters.vak !== null) {
            const currentKlas = vakcurContext.klassen.find(klas => parseInt(klas.slug) === parseInt(vakcurContext.activeFilters.klas));
            setShowTrajectSelect((!!currentKlas && Object.values(currentKlas.trajecten).length > 0) && vakcurContext.activeFilters.traject === null);
        }
    }, [vakcurContext.activeFilters.klas, vakcurContext.activeFilters.vak, vakcurContext.activeFilters.klassen, vakcurContext.activeFilters.traject, vakcurContext.klassen, vakcurContext.vakken]);

    useEffect(() => {
        if (vakcurContext.klassen.length > 0 && videoEl.current !== null) {
            gsap.set(altImgWrapper.current, { opacity: 0 });
            videoEl.current.play();
            setTimeout(() => gsap.to(altImgWrapper.current, { duration: 0.5, opacity: 1, ease: 'power2.inOut', onComplete: () => setShowAltImages(true) }), 1700);
        }
    }, [vakcurContext.klassen, videoUrl]);

    // useEffect(() => {
    //     if (vakcurContext.klassen.length > 0 && videoEl.current !== null) {
    //         videoEl.current.play();
    //     }
    // }, [vakSlug]);

    useLayoutEffect(() => {
        if (videoEl.current === null) return;

        resize();
        window.addEventListener('resize', resize);

        const interval = setInterval(() => {
            if (videoEl.current.currentTime > 6) {
                videoEl.current.currentTime = 2;
            }
        }, 100);

        return () => {
            clearInterval(interval);
            window.removeEventListener('resize', resize);
        };
    });

    return (
        <div className="page-vak">
            {currentCardsFiltered.length === 0 && <div className="page-vak__no-cards-msg">Er zijn geen resultaten gevonden voor de huidige filtercombinatie.</div>}

            {!!currentVakcurKlas ? <>
                <SideYearNav showInfoModal={showInfoModal} setShowInfoModal={setShowInfoModal} />

                <ul className="page-vak__cards">
                    {currentCardsFiltered.map((card, index) => {
                        const style = {};
                        if (!!card.featured_image.thumbnail) style.backgroundImage = `url(${card.featured_image.thumbnail})`;

                        return (
                            <li key={`page-vak-card-${index}`} className={'page-vak__card' + (!card.featured_image.thumbnail ? ' page-vak__card--no-image' : '')}>
                                <button type="button" className="page-vak__card__btn" onClick={() => goToCard(card.slug)}>
                                    <div className="page-vak__card__img-wrapper"><div className="page-vak__card__img" style={style}></div></div>
                                    <span className="page-vak__card__title">{getCardTitle(card)}</span>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </> :
                <ul className="page-vak__card-grid">
                    {currentCardsFiltered.map((card, index) => {
                        return (
                            <li key={`page-vak-grid-card-${index}`} className="page-vak__grid-card">
                                <button className="page-vak__grid-card__btn" type="button" onClick={() => goToCard(card.slug)}>
                                    <span className="page-vak__grid-card__vak">{card.vakken_format.join(', ')}</span>
                                    <span className="page-vak__grid-card__title heading:3">{getCardTitle(card)}</span>
                                    <div className="page-vak__grid-card__excerpt default-styles"><p>{card.excerpt}</p></div>
                                    <div className="page-vak__grid-card__icon"></div>
                                </button>
                            </li>
                        );
                    })}
                </ul>
            }

            {vakcurContext.klassen.length === 0 && <div className="loading-msg">Laden..</div>}

            {!!currentVakcurVak && <TrajectSelectModal show={showTrajectSelect} setShow={setShowTrajectSelect} options={Object.values(currentVakcurVak.trajecten).map(traject => { return { title: traject.name, slug: traject.slug } })} callback={trajectSelected} />}

            <div className={'page-vak__bg-wrapper' + (currentVakcurKlas ? ' page-vak__bg-wrapper--overlay' : '')}>
                <video ref={videoEl} src={videoUrl} type="video/mp4" playsInline muted></video>

                <div ref={altImgWrapper} className={'page-vak__bg-wrapper__alt-images' + (showAltImages ? ' page-vak__bg-wrapper__alt-images--sow' : '')}>
                    {(!!currentVakcurVak && typeof currentVakcurVak.hasOwnProperty['alt_images'] && currentVakcurVak.alt_images.length > 0) && currentVakcurVak.alt_images.map((image, index) => {
                        return <div key={`page-vak-alt-image-${index}`} className="page-vak__bg-wrapper__alt-image" style={{ backgroundImage: `url(${image.thumbnail})` }}></div>;
                    })}
                </div>
            </div>
        </div>
    );
}

export default PageVak;